import { Builder } from 'builder-pattern';

import type EditorJS from '@editorjs/editorjs';
import { type OutputData } from '@editorjs/editorjs';

import { EditorInstancesService } from '../services/EditorInstances';
import parseAsString from 'lodash/toString';

const createParagraphBlock = (text: string = '') =>
  Builder<OutputData['blocks'][0]>().type('paragraph').data({ text }).build();

const consolidateNewlines = (text: string = '') => {
  return text.replace(/\n+/g, '\n');
};

const setText = async (id: string, value: string = ''): Promise<void> => {
  const editorInstance: EditorJS = EditorInstancesService.get(id);
  if (!editorInstance) {
    return;
  }

  const formattedText: string[] = consolidateNewlines(parseAsString(value)).split('\n');

  const data: OutputData = Builder<OutputData>().blocks([]).build();

  formattedText.forEach((newText: string) => newText && data.blocks.push(createParagraphBlock(newText)));

  (async () => {
    await editorInstance.isReady;
    await editorInstance.blocks.render(data);
  })();
};

export { setText };
