export enum PrescriptionStatusEnum {
  PENDING = 'pending',
  PENDING_PAYMENT = 'pending_payment',
  REJECTED = 'rejected',
  SENDING = 'sending',
  SENDING_FAILED = 'sending_failed',
  SENT = 'sent',
  SHIPMENT_CANCELLED = 'shipment_cancelled',
  SHIPPED = 'shipped',
  SIGNED = 'signed',
}

export type PrescriptionStatusEnumType = keyof typeof PrescriptionStatusEnum;
