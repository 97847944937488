import {
  AppointmentType,
  MessageType,
  PatientType,
  ProviderActionKindEnum,
  ProviderActionStatusEnum,
  UserType,
} from '.';

export interface ProviderActionType {
  _rowVariant?: string | undefined;
  actionSubjectId?: string;
  appointment?: AppointmentType;
  assignee?: UserType;
  createdAt?: string;
  description?: string;
  displayKind?: string;
  displayStatus?: string;
  followUpDate?: Date;
  id?: string;
  kind?: ProviderActionKindEnum;
  message?: MessageType;
  patient?: PatientType;
  status?: ProviderActionStatusEnum;
  updatedAt?: string;
  zendeskTicketId?: number;
}

export enum ProviderActionKindLabelEnum {
  prescribe_slit = 'Prescribe SLIT',
  sign_prescription = 'Sign SLIT Rx',
  prescribe_epi = 'Prescribe Epipen',
  prescribe_in_dosespot = 'Prescribe in DoseSpot',
  prescribe_other = 'Prescribe Other Rx - (name below)',
  lab_order_curex = 'Lab Requisition - Curex',
  lab_order_other = 'Lab Requisition - External',
  lab_order_getlabs = 'Lab Requisition - GetLabs',
  lab_order_labcorp = 'Lab Requisition - LabCorp',
  lab_order_quest = 'Lab Requisition - Quest',
  lab_order_examone = 'Lab Requisition - ExamOne',
  arl_self_test = 'Send ARL Self Help Test',
  sign_lab_order_other = 'Sign Lab Requisition',
  scarlet = 'BRL',
  follow_up = 'Follow up',
  other = 'Other',
  account_review = 'Account Review',
  resume_account = 'Resume Account',
  outreach_needed = 'Outreach Needed/Phone call needed',
  stripe_updates = 'Stripe Updates',
  account_hold = 'Account placed on hold',
  expedited_shipment = 'Expedited Shipment',
  patient_info_updates = 'Patient Information Updates',
  verify_patient_info = 'Verify Patient Information',
  confirm_plan_options = 'Confirm Plan options Insurance or Self-Pay',
  upload_insurance_card = 'Upload Insurance Card',
  upload_id = 'Upload ID',
  schedule_testing = 'Scheduling testing appointment',
  create_lab_requisition = "Creating patient's lab requisition",
  input_patient_labs = 'Inputting patient into the labs system',
  schedule_first_consult = 'Schedule First Consultation',
  update_patient_address = 'Updating pt address',
  confirm_account_details = 'Refill messages confirm pt account details',
  verify_payment_method = 'Verify Patient Payment Method',
  update_payment_method = 'Update Payment Method on File',
  process_special_subscription = 'Process Subscription For special plans',
  resolve_payment_decline = 'Resolve Payment Decline Issues',
  generate_invoice = 'Generate & Send Patient Invoice',
  confirm_insurance_submission = 'Confirm Insurance Submission',
  verify_insurance_reimbursement = 'Verify Insurance Reimbursement Status',
  review_refund_request = 'Review & Approve Refund Request',
  issue_refund = 'Issue Patient Refund',
  send_payment_reminder = 'Send Payment Past Due Reminder',
  handle_chargeback = 'Handle Chargeback & Dispute Cases',
  update_billing_cycle = 'Update Billing Cycle for Patient',
  resubmit_denied_claims = 'Request to Resubmit Denied Insurance Claims',
  update_insurance_details = 'Update Insurance Plan Details',
  confirm_insurance_payment = 'Confirm Insurance Payment Received',
  adjust_patient_balance = 'Adjust Patient Balance After Insurance Payment',
  verify_deductible = 'Verify Deductible & Out-of-Pocket Costs',
  patient_complaint = 'Patient Complaint or Concern',
  escalated_issue = 'Extremely Escalated Patient Issue',
  refund_request = 'Patient Refund Request',
  billing_dispute = 'Patient Billing Dispute',
  missed_appointment = 'Missed Appointment & Follow-Up',
  portal_access_issue = 'Patient Portal Access Issue',
  provider_credentialing = 'Provider Credentialing & Licensing Update',
  medical_records_transfer = 'Request Medical Records Transfer',
  tech_support = 'Tech support account issues',
  update_weekly_schedule = 'Update Provider Weekly Schedule',
  adjust_availability = 'Adjust Provider Availability',
  block_time = 'Block Off Time for Provider',
  reschedule_appointments = 'Reschedule Patient Appointments',
  assign_new_patients = 'Assign New Patients to Provider',
  update_consultation_times = "Update Provider's Preferred Consultation Times",
  confirm_coverage = 'Confirm Provider Coverage for Absences',
  add_provider_calendly = 'Add New Provider to Calendly',
  update_reminders = 'Update Automated Reminders & Notifications in Calendly',
  verify_schedule_sync = 'Verify Provider Schedule Syncs Correctly with Calendly',
  medical_records_request = 'Medical records request',
  fill_patient_form = 'Fill out form for patient (ffa/fsa/faa)',
  call_previous_providers = 'Call previous providers for records',
  medical_necessity_letter = 'Medical letter of necessity',
  fill_school_form = 'Fill out school form for patient',
  send_medical_records = 'Send medical records to insurance company',
  send_hipaa_form = 'Send hippa form',
  check_django = 'Check Django',
  message_pediatric = 'Message pediatric patients',
  check_early_appointments = 'Check early appointments',
  check_late_appointments = 'Check late appointments',
  check_provider_state_schedule = 'Check provider schedule for state',
  check_food_appointments = 'Check appointments for food patients',
  prior_authorization = 'Prior authorization for medication',
  ac_renewals = 'Renewals for AC',
  refill_messages = 'Refill messages',
  send_ac_refills = 'Send refills to AC',
  insurance_consultations = 'Insurance consultations',
  switch_self_pay = 'Switch to self pay',
  change_address = 'Change address',
  aap_follow_up = 'AAP follow up',
  send_epi_pen = 'Send epi pen',
  no_response_outreach = 'Reach out to no response patients',
  refax_slit = 'Refax slit order',
  check_hbt = 'Check hbt folder',
  check_hold_patients = 'Check on hold patients in AC slack thread',
  put_lab_orders = 'Put in lab orders',
  lab_results_outreach = 'Reach out to lab for patients results',
  async_provider_mapping = 'Async mapping for provider',
  update_provider_license = 'Update provider license',
  update_provider_calendly = 'Update calendly for provider',
  check_no_shows = 'Check no show appt',
  check_results = 'Check results',
  check_faxes = 'Check faxes',
  send_medications = 'Send over medications',
  send_weightless_rx = 'Send weightless prescription',
  call_patient_info = 'Call patient for date of birth/last name',
  call_patient_followup = 'Call patient for follow up questions',
  call_pharmacy = 'Call pharmacy to check prescription',
  prescribe_specialty = 'Prescribe Xolair and dupixent',
  google_survey = 'Google survey',
  art_surveys = 'Art surveys',
}

export const providerActionKindOptions = Object.entries(ProviderActionKindLabelEnum).map(([key, value]) => ({
  text: value,
  value: key,
}));

export type TaskTypeKey = keyof typeof ProviderActionKindLabelEnum;
export type TaskTypeValue = typeof ProviderActionKindLabelEnum[TaskTypeKey];

export function getProviderActionKindLabelKeyByValue(value: TaskTypeValue): TaskTypeKey {
  return Object.entries(ProviderActionKindLabelEnum).find(([_, val]) => val === value)?.[0] as TaskTypeKey;
}

export enum TaskCategory {
  PREVIOUS_ACTIONS = 'Previous Actions',
  BILLING_AND_PAYMENT = 'Billing & Payment',
  DAILY_CLINICAL = 'Daily Clinical',
  INSURANCE_SPECIFIC = 'Insurance-Specific',
  OTHER = 'Other',
  PATIENT_ONBOARDING = 'Patient Onboarding',
  PROVIDER_SCHEDULING_UPDATES = 'Provider Scheduling Updates',
  SUPPORT_RELATED = 'Support Related',
}

export type TaskCategoryKey = keyof typeof TaskCategory;
export type TaskCategoryValue = typeof TaskCategory[TaskCategoryKey];

export const TaskCategories = {
  [TaskCategory.PREVIOUS_ACTIONS]: new Set([
    ProviderActionKindLabelEnum.prescribe_slit,
    ProviderActionKindLabelEnum.sign_prescription,
    ProviderActionKindLabelEnum.prescribe_epi,
    ProviderActionKindLabelEnum.prescribe_in_dosespot,
    ProviderActionKindLabelEnum.prescribe_other,
    ProviderActionKindLabelEnum.lab_order_curex,
    ProviderActionKindLabelEnum.lab_order_other,
    ProviderActionKindLabelEnum.lab_order_getlabs,
    ProviderActionKindLabelEnum.lab_order_labcorp,
    ProviderActionKindLabelEnum.lab_order_quest,
    ProviderActionKindLabelEnum.lab_order_examone,
    ProviderActionKindLabelEnum.arl_self_test,
    ProviderActionKindLabelEnum.sign_lab_order_other,
    ProviderActionKindLabelEnum.scarlet,
    ProviderActionKindLabelEnum.follow_up,
    ProviderActionKindLabelEnum.other,
  ]),
  [TaskCategory.BILLING_AND_PAYMENT]: new Set([
    ProviderActionKindLabelEnum.confirm_insurance_submission,
    ProviderActionKindLabelEnum.generate_invoice,
    ProviderActionKindLabelEnum.handle_chargeback,
    ProviderActionKindLabelEnum.issue_refund,
    ProviderActionKindLabelEnum.process_special_subscription,
    ProviderActionKindLabelEnum.resolve_payment_decline,
    ProviderActionKindLabelEnum.review_refund_request,
    ProviderActionKindLabelEnum.send_payment_reminder,
    ProviderActionKindLabelEnum.update_billing_cycle,
    ProviderActionKindLabelEnum.update_payment_method,
    ProviderActionKindLabelEnum.verify_insurance_reimbursement,
    ProviderActionKindLabelEnum.verify_payment_method,
  ]),
  [TaskCategory.DAILY_CLINICAL]: new Set([
    ProviderActionKindLabelEnum.aap_follow_up,
    ProviderActionKindLabelEnum.ac_renewals,
    ProviderActionKindLabelEnum.art_surveys,
    ProviderActionKindLabelEnum.async_provider_mapping,
    ProviderActionKindLabelEnum.call_patient_followup,
    ProviderActionKindLabelEnum.call_patient_info,
    ProviderActionKindLabelEnum.call_pharmacy,
    ProviderActionKindLabelEnum.call_previous_providers,
    ProviderActionKindLabelEnum.change_address,
    ProviderActionKindLabelEnum.check_django,
    ProviderActionKindLabelEnum.check_early_appointments,
    ProviderActionKindLabelEnum.check_faxes,
    ProviderActionKindLabelEnum.check_food_appointments,
    ProviderActionKindLabelEnum.check_hbt,
    ProviderActionKindLabelEnum.check_hold_patients,
    ProviderActionKindLabelEnum.check_late_appointments,
    ProviderActionKindLabelEnum.check_no_shows,
    ProviderActionKindLabelEnum.check_provider_state_schedule,
    ProviderActionKindLabelEnum.check_results,
    ProviderActionKindLabelEnum.fill_patient_form,
    ProviderActionKindLabelEnum.fill_school_form,
    ProviderActionKindLabelEnum.google_survey,
    ProviderActionKindLabelEnum.insurance_consultations,
    ProviderActionKindLabelEnum.lab_results_outreach,
    ProviderActionKindLabelEnum.medical_necessity_letter,
    ProviderActionKindLabelEnum.medical_records_request,
    ProviderActionKindLabelEnum.message_pediatric,
    ProviderActionKindLabelEnum.missed_appointment,
    ProviderActionKindLabelEnum.no_response_outreach,
    ProviderActionKindLabelEnum.prescribe_specialty,
    ProviderActionKindLabelEnum.prior_authorization,
    ProviderActionKindLabelEnum.put_lab_orders,
    ProviderActionKindLabelEnum.refax_slit,
    ProviderActionKindLabelEnum.refill_messages,
    ProviderActionKindLabelEnum.send_ac_refills,
    ProviderActionKindLabelEnum.send_epi_pen,
    ProviderActionKindLabelEnum.send_hipaa_form,
    ProviderActionKindLabelEnum.send_medical_records,
    ProviderActionKindLabelEnum.send_medications,
    ProviderActionKindLabelEnum.send_weightless_rx,
    ProviderActionKindLabelEnum.switch_self_pay,
    ProviderActionKindLabelEnum.update_provider_calendly,
    ProviderActionKindLabelEnum.update_provider_license,
  ]),
  [TaskCategory.INSURANCE_SPECIFIC]: new Set([
    ProviderActionKindLabelEnum.adjust_patient_balance,
    ProviderActionKindLabelEnum.confirm_insurance_payment,
    ProviderActionKindLabelEnum.resubmit_denied_claims,
    ProviderActionKindLabelEnum.update_insurance_details,
    ProviderActionKindLabelEnum.verify_deductible,
  ]),
  [TaskCategory.OTHER]: new Set([
    ProviderActionKindLabelEnum.billing_dispute,
    ProviderActionKindLabelEnum.escalated_issue,
    ProviderActionKindLabelEnum.medical_records_transfer,
    ProviderActionKindLabelEnum.missed_appointment,
    ProviderActionKindLabelEnum.other,
    ProviderActionKindLabelEnum.patient_complaint,
    ProviderActionKindLabelEnum.portal_access_issue,
    ProviderActionKindLabelEnum.provider_credentialing,
    ProviderActionKindLabelEnum.refund_request,
    ProviderActionKindLabelEnum.tech_support,
  ]),
  [TaskCategory.PATIENT_ONBOARDING]: new Set([
    ProviderActionKindLabelEnum.confirm_account_details,
    ProviderActionKindLabelEnum.confirm_plan_options,
    ProviderActionKindLabelEnum.create_lab_requisition,
    ProviderActionKindLabelEnum.input_patient_labs,
    ProviderActionKindLabelEnum.schedule_first_consult,
    ProviderActionKindLabelEnum.schedule_testing,
    ProviderActionKindLabelEnum.update_patient_address,
    ProviderActionKindLabelEnum.upload_id,
    ProviderActionKindLabelEnum.upload_insurance_card,
    ProviderActionKindLabelEnum.verify_patient_info,
  ]),
  [TaskCategory.PROVIDER_SCHEDULING_UPDATES]: new Set([
    ProviderActionKindLabelEnum.add_provider_calendly,
    ProviderActionKindLabelEnum.adjust_availability,
    ProviderActionKindLabelEnum.assign_new_patients,
    ProviderActionKindLabelEnum.block_time,
    ProviderActionKindLabelEnum.confirm_coverage,
    ProviderActionKindLabelEnum.provider_credentialing,
    ProviderActionKindLabelEnum.reschedule_appointments,
    ProviderActionKindLabelEnum.update_consultation_times,
    ProviderActionKindLabelEnum.update_reminders,
    ProviderActionKindLabelEnum.update_weekly_schedule,
    ProviderActionKindLabelEnum.verify_schedule_sync,
  ]),
  [TaskCategory.SUPPORT_RELATED]: new Set([
    ProviderActionKindLabelEnum.account_hold,
    ProviderActionKindLabelEnum.account_review,
    ProviderActionKindLabelEnum.expedited_shipment,
    ProviderActionKindLabelEnum.outreach_needed,
    ProviderActionKindLabelEnum.patient_info_updates,
    ProviderActionKindLabelEnum.resume_account,
    ProviderActionKindLabelEnum.stripe_updates,
  ]),
} as const;

export const taskCategoriesOptions = Object.entries(TaskCategories).reduce(
  (items, [category, taskTypes]) => [
    ...items,
    { category, types: [...taskTypes].map((label) => ({ label, value: getProviderActionKindLabelKeyByValue(label) })) },
  ],
  []
) as { category: TaskCategoryValue; types: { label: TaskTypeValue; value: TaskTypeKey }[] }[];
