/**
 * @description **deflate** – Uses the same compression algorithm as gzip (DEFLATE) but without the additional headers and checksum. This can result in slightly better compression ratios for raw data.
 * @description **deflate-raw** – The pure DEFLATE algorithm without headers or footers. This is the most compact format but lacks integrity checks.
 * @description **gzip** – The most common choice for compression, especially when interoperability with web servers is needed. It includes a small header and checksum, which add overhead but improve reliability.
 */
export type UseCompressionType = 'deflate' | 'deflate-raw' | 'gzip';

function createUseCompression(type: UseCompressionType = 'deflate-raw') {
  async function checkCompressionSupport() {
    try {
      new window.CompressionStream(type);
      return true;
    } catch (e) {
      console.error(`[useCompression]: Unsupported compression type ${type}. Fallback to gzip`, e);
      return false;
    }
  }

  let compressionType: UseCompressionType = type;

  (async () => {
    if (!(await checkCompressionSupport())) {
      compressionType = 'gzip';
    }
  })();

  async function compress(input: string) {
    if (!input || !window?.CompressionStream) {
      return input;
    }

    const encoder = new TextEncoder();
    const inputUint8Array = encoder.encode(input);
    const stream = new Blob([inputUint8Array]).stream().pipeThrough(new window.CompressionStream(compressionType));
    const compressedBlob = await new Response(stream).blob();
    const buffer = await compressedBlob.arrayBuffer();

    return btoa(String.fromCharCode(...new Uint8Array(buffer)));
  }

  async function decompress(input: string) {
    if (!input || !window?.DecompressionStream) {
      return input;
    }

    const binaryString = atob(input);

    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    const stream = new Blob([uint8Array.buffer]).stream().pipeThrough(new window.DecompressionStream(compressionType));
    const decompressedBlob = await new Response(stream).blob();

    return decompressedBlob.text();
  }

  return {
    compress,
    decompress,
  };
}

const instances: Partial<Record<UseCompressionType, ReturnType<typeof createUseCompression>>> = {};

export function useCompression(type: UseCompressionType = 'deflate-raw') {
  return instances[type] || (instances[type] = createUseCompression(type));
}
