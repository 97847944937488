import type { PrescriptionType } from '@/types';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { diffObjects } from '@/composables/getPrescriptionDiff/utils/diffObjects';
import { diffCounts } from '@/composables/getPrescriptionDiff/utils/diffCounts';
import { useStaffersStore } from '@/stores/staffers';

export function diffWithOther(
  prescriptionCurrent: MaybeRefOrGetter<PrescriptionType<Record<string, any>>>,
  prescriptionPrevious: MaybeRefOrGetter<PrescriptionType<Record<string, any>>>
) {
  const staffersStore = useStaffersStore();

  if (!staffersStore.currentStafferBetaFeatures['SLITPrescriptionDiff']) {
    return null;
  }

  const rxCurrent = toValue(prescriptionCurrent);
  const rxPrevious = toValue(prescriptionPrevious);

  const antigens = diffObjects(rxPrevious.data.antigens, rxCurrent.data.antigens);
  const antigensCounts = diffCounts(antigens, true);

  const dilution = diffObjects(rxPrevious.data.treatment_dilution, rxCurrent.data.treatment_dilution);
  const dilutionCounts = diffCounts(dilution);

  return {
    antigens,
    antigensCounts,
    hasAntigensDiff: !!antigensCounts.total,

    dilution,
    dilutionCounts,
    hasDilutionsDiff: !!dilutionCounts.total,

    defaultAntigens: rxPrevious.data.antigens,
    defaultDilution: rxPrevious.data.treatment_dilution,
  };
}
