
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import type { BvTableFieldArray } from 'bootstrap-vue/src/components/table';
import { BvEvent } from 'bootstrap-vue';
import { PatientProgressStageEnum, PaymentPeriodEnum } from '@/types';
import { ClipboardService } from '@/services/ClipboardService';
import LinkToPatient from '@/components/LinkToPatient.vue';

export const PAGE_LIMIT = 25;

@Component({
  components: { LinkToPatient },
})
export default class SearchableTable extends Vue {
  filter = '';
  innerSortBy = '';
  innerSortDesc = true;
  @Prop({ default: 1, type: Number }) public page: number;
  @Prop({ default: '' }) private contentClass!: string | undefined;
  @Prop({ default: () => [] }) private tableRows!: Array<any>;
  @Prop({ default: () => [] }) private fields!: BvTableFieldArray;
  @Prop({ default: () => [] }) private filterOn!: Array<string>;
  @Prop({ default: 'createdAt' }) private defaultSortColumn!: string;
  @Prop({ default: 'Filter by patient name, state, email' }) private searchPlaceholder!: string;
  @Prop({ default: true }) private sortDesc!: boolean;
  @Prop({ default: false }) private loading!: boolean;
  @Prop({ default: false }) private stripped!: boolean;
  @Prop({ default: false }) private showEmpty!: boolean;
  @Prop({ default: false, type: Boolean }) private disablePagination!: boolean;
  @Prop() private onRowClicked?: (item: { id: string }) => void | undefined;
  // totalRows used for server-side pagination when we can not calculate it from `tableRows` length
  @Prop() private totalRows: number | undefined;
  // setting onSearch will disable local search, used for server-side search
  @Prop() private onSearch?: (search: string) => void | undefined;
  // setting onSortChanged will disable local sorting, used for server-side sorting and pagination
  @Prop() private onSortChanged?: (ctx: { sortBy: string; sortDesc: boolean }) => void | undefined;
  // setting onPageClicked will disable local pagination, used for server-side sorting and pagination
  @Prop() private onPageClicked?: (page: number) => void | undefined;

  get perPage() {
    return PAGE_LIMIT;
  }

  get currentSortBy() {
    return (this.innerSortDesc ? '-' : '') + this.innerSortBy;
  }

  get getTotalRows() {
    return this.totalRows ? this.totalRows : this.tableRows.length;
  }

  get showPagination(): boolean {
    return !this.disablePagination && this.getTotalRows > PAGE_LIMIT;
  }

  mounted() {
    // when returning back to table database, set search field and sorting from the query string
    // WARNING: parent component still will need to sort the table rows data themselves, this relates only to table UI
    if (this.$route.query.search) {
      this.filter = this.$route.query.search as string;
    }

    if (this.$route.query.sortBy) {
      if ((this.$route.query.sortBy as string).startsWith('-')) {
        this.innerSortBy = this.$route.query.sortBy.slice(1) as string;
        this.innerSortDesc = true;
      } else {
        this.innerSortBy = this.$route.query.sortBy as string;
        this.innerSortDesc = false;
      }
    } else {
      this.innerSortBy = this.defaultSortColumn;
      this.innerSortDesc = this.sortDesc;
    }
  }

  displayStage(progressStageStr: string): PatientProgressStageEnum {
    if (!progressStageStr) {
      return PatientProgressStageEnum.unknown;
    }

    return PatientProgressStageEnum[progressStageStr as unknown as keyof typeof PatientProgressStageEnum];
  }

  displayPaymentPeriod(paymentPeriodStr: string): PaymentPeriodEnum {
    if (!paymentPeriodStr) {
      return PaymentPeriodEnum.unknown;
    }

    return PaymentPeriodEnum[paymentPeriodStr as unknown as keyof typeof PaymentPeriodEnum];
  }

  sortChangeHandler(ctx: { sortBy: string; sortDesc: boolean }) {
    // when changing sorting, drop pagination to 1 page
    this.innerSortBy = ctx.sortBy;
    this.innerSortDesc = ctx.sortDesc;
    this.$emit('update:page', 1);

    if (this.onSortChanged) {
      // use server-side sorting, if handler is received
      this.onSortChanged(ctx);
    }

    this.updateQueryParams();
  }

  async pageClickHandler(bvEvent: BvEvent, page: number) {
    this.$emit('update:page', page);

    if (this.onPageClicked) {
      // fetch additional data before using local pagination
      await this.onPageClicked(page);
    }
  }

  async updateQueryParams(): Promise<void> {
    try {
      await this.$router.push({
        query: { ...this.$route.query, sortBy: this.currentSortBy, search: this.filter?.trim?.() || undefined },
      });
    } catch (error: any) {
      if (error?.name !== 'NavigationDuplicated') {
        console.error(error);
      }
    }
  }

  async copyToClientBuffer(valueToCopy: string = ''): Promise<void> {
    if (!(await ClipboardService.write(valueToCopy))) {
      this.showToast('Copy to clipboard', 'Sorry, cannot copy', 'danger');
      return;
    }

    this.showToast('Copied to clipboard', valueToCopy, 'success');
  }

  showToast(title: string, text: string, variant: string): void {
    this.$bvToast.toast(text, {
      autoHideDelay: 2000,
      title,
      toaster: 'b-toaster-top-right',
      variant,
    });
  }

  @Emit('rowContextmenu')
  public emitRowContextmenu(item: { id: string }, rowNo: number, event: PointerEvent) {
    return { item, rowNo, event };
  }

  @Watch('filter')
  onFilterChange(value: string) {
    const searchString = value?.trim?.() ?? '';

    if (this.onSearch) {
      // use server side search
      this.onSearch(searchString);
    }
  }

  @Watch('page')
  onPageChange(newPage: number, oldPage: number) {
    if (this.$refs?.['pagination'] && newPage !== oldPage) {
      (this.$refs['pagination'] as any).currentPage = newPage;
    }
  }
}
