import { type MaybeRefOrGetter, reactiveComputed, toValue } from '@vueuse/core';
import type { PatientType } from '@/types';
import { computed } from 'vue';
import type { RawLocation, Location } from 'vue-router';
import merge from 'lodash/merge';

export type UsePatientLinksOptions = Omit<Location, 'path' | 'name'>;

/**
 * @alias UsePatientLinks Vue component
 */
export function usePatientLinks(
  patient: MaybeRefOrGetter<PatientType>,
  options?: MaybeRefOrGetter<UsePatientLinksOptions>
) {
  const ids = computed(() => {
    const value = toValue(patient);

    if (!value) {
      return {};
    }

    return {
      patientId: value?.id,
      chatId: value?.chatId,
    };
  });

  const location = reactiveComputed<UsePatientLinksOptions>(() => toValue(options) || {});

  const profile = computed((): RawLocation => {
    if (!ids.value?.patientId) {
      return null;
    }

    return createRouterLocation({
      name: 'patient-details',
      params: { pk: ids.value.patientId },
    });
  });

  const messaging = computed((): RawLocation => {
    if (!ids.value?.chatId) {
      return null;
    }

    return createRouterLocation({
      name: 'messaging',
      params: { pk: ids.value.chatId },
      query: { 'patient-id': ids.value.patientId },
    });
  });

  function createRouterLocation(definition: Location): Location {
    return merge({}, { ...location }, definition);
  }

  return {
    messaging,
    profile,
  };
}
