import { createSharedComposable, debounceFilter, useStorageAsync, watchOnce } from '@vueuse/core';
import { ChatArgumentsModel, type ChatModel, ChatsCacheService, stripHtml, useMessagingApi } from '~/messaging';
import { MessageChannelEnum } from '@/types';
import { useCompression } from '@/composables/useCompression';
import { useRoot } from '@/composables/atoms/useRoot';
import { showError } from '@/components/errors';
import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';
import { computed, watch } from 'vue';
import { defer } from '@/components/Editor/helpers';
import { mapKeys, omit } from 'lodash';
import * as Sentry from '@sentry/browser';

type MessageDraft = [MessageChannelEnum, string];
const DRAFTS_STORAGE_NAME = 'CXChatMessagesDraftsStorage';
const DRAFTS_STORAGE_DEBOUNCE_TIME = 250;
const DRAFTS_AUTO_RESOLVE_TIME = 2000;

function keyToId(key: ChatModel['key']): ChatModel['id'] | null {
  return key ? /^[\w-]+/i.exec(key)?.[0] || null : null;
}

function isMessageEmpty(message: string): boolean {
  return !message || !(stripHtml(message) || '').replace(/\s+/g, '')?.length;
}

function createUseMessagingDrafts() {
  const start = performance.now();
  const isReady = defer<void>();
  const root = useRoot();

  const { getChat } = useMessagingApi();
  const { compress, decompress } = useCompression('deflate-raw');

  const drafts = useStorageAsync<Record<ChatModel['id'], MessageDraft>>(DRAFTS_STORAGE_NAME, {}, localStorage, {
    eventFilter: debounceFilter(DRAFTS_STORAGE_DEBOUNCE_TIME),
    onError(error: any) {
      isReady.resolve();
      console.error('[useMessagingDrafts] LocalStorage failed:', error);
      Sentry.captureException(error, {
        fingerprint: ['useMessagingDraftsStorageFailed'],
        level: 'error',
        tags: { composable: 'useMessagingDrafts' },
      });
    },
    serializer: {
      async write(value: Record<ChatModel['id'], MessageDraft>): Promise<string> {
        return compress(JSON.stringify(value));
      },
      async read(raw: string): Promise<Record<ChatModel['id'], MessageDraft>> {
        if (!raw) {
          isReady.resolve();
          return null;
        }

        const stringify = await decompress(raw);

        /** TODO: Remove mapKeys after some time. This is just for backward compatibility when Key has been used */
        return mapKeys(JSON.parse(stringify || '{}'), (_, key) => keyToId(key));
      },
    },
    shallow: true,
  });

  const isEmpty = computed(() => ![...Object.keys(drafts.value)].length);

  function clear(): void {
    drafts.value = {};
  }

  function get(chatKey: ChatModel['key'] | ChatModel['id']): MessageDraft {
    return drafts.value[keyToId(chatKey)] || [undefined, undefined];
  }

  function has(chatKey: ChatModel['key'] | ChatModel['id']): boolean {
    return !!drafts.value[keyToId(chatKey)];
  }

  function remove(chatKey: ChatModel['key'] | ChatModel['id']): boolean {
    const id = keyToId(chatKey);

    if (!id) {
      return false;
    }

    drafts.value = omit(drafts.value, [id]);

    return true;
  }

  function set(chatKey: ChatModel['key'] | ChatModel['id'], channel: MessageChannelEnum, message: string): boolean {
    const id = keyToId(chatKey);

    if (!id || !channel) {
      return false;
    }

    if (isMessageEmpty(message)) {
      return remove(chatKey);
    }

    /** Avoid unnecessary updates */
    if (drafts.value[id]?.[1] === message && drafts.value[id]?.[0] === channel) {
      return true;
    }

    drafts.value = { ...drafts.value, [id]: [channel, message] };

    return true;
  }

  function getUniqueChatIds(): ChatModel['id'][] {
    if (isEmpty.value) {
      return [];
    }

    return [...Object.keys(drafts.value)].filter((id: ChatModel['id']) => id && !ChatsCacheService.hasById(id));
  }

  async function loadChat(key: ChatModel['key'] | ChatModel['id']): Promise<ChatModel | void> {
    try {
      const id = keyToId(key);

      if (!id) {
        return;
      }

      if (ChatsCacheService.hasById(id)) {
        return ChatsCacheService.getById(id);
      }

      const argumentsModel = new ChatArgumentsModel();
      argumentsModel.id = id;

      const chat = await getChat(argumentsModel);

      if (!chat) {
        return;
      }

      return ChatsCacheService.add(chat);
    } catch (error) {
      console.error(`[useMessagingDrafts][${key}] load chat failed:`, error);
      showError(root, parseGraphQLErrorsAsText(error));
    }
  }

  async function fetchMissingChats(): Promise<void> {
    await Promise.all(getUniqueChatIds().map(loadChat));
  }

  async function autoResolve(): Promise<void> {
    try {
      await Promise.race([
        isReady,
        new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error('useMessagingDrafts: auto-resolve timeout reached')),
            DRAFTS_AUTO_RESOLVE_TIME
          )
        ),
      ]);
    } catch (error) {
      const duration = performance.now() - start;

      isReady.resolve();

      console.warn(`[useMessagingDrafts][${duration}ms] auto-resolve timeout reached:`, error);
      Sentry.captureException(error, {
        extra: { duration },
        fingerprint: ['useMessagingDraftsTimeout'],
        level: 'warning',
        tags: { composable: 'useMessagingDrafts' },
      });
    }
  }

  watch(drafts, fetchMissingChats, { flush: 'post' });
  watchOnce(drafts, () => isReady.resolve());

  /** @description Skip if HRM **/
  if (!module?.hot?.data) {
    (async () => await autoResolve())();
  }

  return {
    // State
    drafts,

    // Getters
    isEmpty,
    get isReady(): Promise<void> {
      return isReady;
    },

    // Actions
    clear,
    get,
    has,
    remove,
    set,
  };
}

let instance: typeof createUseMessagingDrafts;

export function useMessagingDrafts() {
  return (instance || (instance = createSharedComposable(createUseMessagingDrafts)))();
}
