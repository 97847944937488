
import { computed, defineComponent, toRef, toRefs } from 'vue';

import { useRoot } from '@/composables/atoms/useRoot';

import {
  ChatModel,
  ChatsCacheService,
  getChatAssignees,
  getReadableTimeDistance,
  isAutomationBot,
  MessageModel,
  MessagesCacheService,
  stripHtml,
  useMessaging,
  useMessagingReplySuggestions,
  useMessagingStore,
} from '~/messaging';

import { useStaffersStore } from '@/stores/staffers';

import { MessageCategoryEnum, MessageChannelEnum } from '@/types';
import { useMessagingDrafts } from '~/messaging/composables/useMessagingDrafts';

const BAD_MESSAGE_BODY: '-' = '-' as const;

const ChatInboxChat = defineComponent({
  props: {
    chatKey: {
      default: '',
      required: false,
      type: String,
    },
    draft: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  setup(props) {
    const { chatKey, draft } = toRefs(props);

    const root = useRoot();

    const staffersStore = useStaffersStore();
    const staffers = toRef(staffersStore, 'staffers');

    const messagingStore = useMessagingStore();
    const messagingDrafts = useMessagingDrafts();

    const activeChat = toRef(messagingStore, 'activeChat');
    const inboxChatsLastMessage = toRef(messagingStore, 'inboxChatsLastMessage');
    const inboxChatsLastClientMessage = toRef(messagingStore, 'inboxChatsLastClientMessage');
    const timestamp = toRef(messagingStore, 'timestamp');

    const { setActiveChat } = messagingStore;

    const { updateUrl, restoreChat } = useMessaging();
    const { generateReplySuggestions } = useMessagingReplySuggestions(chatKey, {});

    const chatHasUnansweredClientMessage = computed(
      (): boolean =>
        inboxChatsLastMessage.value?.[chatKey.value] &&
        inboxChatsLastClientMessage.value?.[chatKey.value] &&
        inboxChatsLastMessage.value?.[chatKey.value] === inboxChatsLastClientMessage.value?.[chatKey.value]
    );

    const chat = computed((): ChatModel => ChatsCacheService.get(chatKey.value) || new ChatModel());
    const latestMessage = computed(
      (): MessageModel => MessagesCacheService.get(inboxChatsLastMessage.value?.[chatKey.value]) || new MessageModel()
    );

    const isPriorityMessage = computed((): boolean => latestMessage.value.priority > 0);

    const isActive = computed((): boolean => chat.value.key === activeChat.value);

    const inboxChatClasses = computed((): string[] =>
      [`ChatInboxChat--key-${chat.value.key}`, isPriorityMessage.value ? 'ChatInboxChat--priority' : ''].filter(Boolean)
    );

    const timeElapsedFromLastMessage = computed((): string => {
      if (latestMessage.value.createdAt) {
        return getReadableTimeDistance(latestMessage.value.createdAt, timestamp.value);
      }

      return chat.value.updatedAt.length ? getReadableTimeDistance(chat.value.updatedAt, timestamp.value) || '' : '';
    });

    const draftMessage = computed((): string => {
      if (!draft.value || !messagingDrafts.has(chatKey.value)) {
        return '';
      }

      const [_, message] = messagingDrafts.get(chatKey.value);

      if (!message) {
        return '';
      }

      return stripHtml(message);
    });

    const messageBody = computed((): string => {
      if (draftMessage.value) {
        return draftMessage.value;
      }

      if (
        isAutomationBot(latestMessage.value.authorId) ||
        latestMessage.value.category === MessageCategoryEnum.CALL_TRANSCRIPT
      ) {
        return `🤖: ${
          latestMessage.value.subject ||
          latestMessage.value.templateName ||
          latestMessage.value.shortTextBody ||
          BAD_MESSAGE_BODY
        }`;
      }

      if (latestMessage.value.channel === MessageChannelEnum.ACTION) {
        return `📝: ${latestMessage.value.shortTextBody || BAD_MESSAGE_BODY}`;
      }

      if (latestMessage.value.channel === MessageChannelEnum.NOTE) {
        return `🟨: ${latestMessage.value.shortTextBody || BAD_MESSAGE_BODY}`;
      }

      return latestMessage.value.shortTextBody || BAD_MESSAGE_BODY;
    });

    const messageTextClasses = computed((): string[] => {
      const textColor: 'text-muted' | 'text-dark' | 'text-white' = isActive.value
        ? 'text-white'
        : chatHasUnansweredClientMessage.value
        ? 'text-dark'
        : 'text-muted';
      const textWeight: 'font-weight-bold' | 'font-weight-normal' = chatHasUnansweredClientMessage.value
        ? 'font-weight-bold'
        : 'font-weight-normal';

      return [textColor, textWeight];
    });

    const popoverKey = computed((): string => 'popover-chat-display-name-' + chat.value.key);

    const popoverContent = computed((): string => {
      if (latestMessage.value.templateName.length) {
        return `Automation: ${latestMessage.value.subject || latestMessage.value.templateName}`;
      }

      if (!latestMessage.value.shortTextBody) {
        return BAD_MESSAGE_BODY;
      }

      return `${latestMessage.value.shortTextBody} <br />${getChatAssignees(
        chat.value.staffersIds,
        chat.value.teamsIds,
        staffers.value
      )}`;
    });

    const updateCurrentChat = async (): Promise<void> => {
      root.$emit('bv::hide::popover', popoverKey.value);

      if (props.draft) {
        await restoreChat(chat.value.id);
        return;
      }

      setActiveChat(chat.value.key);
      await updateUrl(chat.value);
    };

    return {
      inboxChatClasses,
      messageTextClasses,

      chat,
      draft,
      isPriorityMessage,
      isActive,
      timeElapsedFromLastMessage,

      messageBody,
      popoverKey,
      popoverContent,

      updateCurrentChat,
      generateReplySuggestions,
    };
  },
});

export default ChatInboxChat;
