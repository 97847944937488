import Vue from 'vue';
import type { Route, RouteConfig } from 'vue-router';
import VueRouter from 'vue-router';
import type { NavigationGuardNext } from 'vue-router/types/router';
import {
  useNavigationGuardBeforeEnterCheckParams,
  useNavigationGuardBeforeEnterVerifyWindowsBetaFeature,
} from './navigationGuards';

Vue.use(VueRouter);

/**
 * add data to query paras shown in the current page address field
 * @deprecated Use this.$router instead
 */
export const addToQueryParams = (route: Route, data: Record<string, unknown>) => {
  let queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());
  queryParams = Object.assign(queryParams, data) as { [key: string]: string };
  history.pushState(
    {},
    route.name ? route.name : '',
    route.path +
      '?' +
      Object.keys(queryParams)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]);
        })
        .join('&')
  );
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'dashboard',
    beforeEnter: (_to: Route, _from: Route, next: NavigationGuardNext) => {
      const token = window?.localStorage?.getItem('token') || '';
      const name = token.length ? 'diagnostics' : 'login';

      console.log('jwt token length', token.length);
      console.log('name', name);

      next({ name });
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
  },
  {
    path: '/subscriptions/new-ac-prescription',
    name: 'ac-prescription-create',
    component: () =>
      import(/* webpackChunkName: "ac-prescription-create" */ './views/subscriptions/DropsPrescriptionFormAC.vue'),
  },
  {
    path: '/subscriptions/ac-prescriptions/:pk',
    name: 'ac-prescription-details',
    component: () =>
      import(/* webpackChunkName: "ic-prescription-details" */ './views/subscriptions/DropsPrescriptionFormAC.vue'),
  },
  {
    path: '/subscriptions/new-ic-prescription',
    name: 'ic-prescription-create',
    component: () =>
      import(/* webpackChunkName: "ic-prescription-create" */ './views/subscriptions/DropsPrescriptionFormIC.vue'),
  },
  {
    path: '/subscriptions/prescriptions/:pk',
    name: 'ic-prescription-details',
    component: () =>
      import(/* webpackChunkName: "ic-prescription-details" */ './views/subscriptions/DropsPrescriptionFormIC.vue'),
  },
  {
    path: '/diagnostics',
    name: 'diagnostics',
    component: () => import(/* webpackChunkName: "diagnostics" */ './views/diagnostics/Diagnostics.vue'),
  },
  {
    path: '/diagnostics/lab-requisitions/:pk',
    name: 'lab-requisition-details',
    component: () =>
      import(/* webpackChunkName: "requisition-details" */ './views/diagnostics/LabRequisitionDetails.vue'),
  },
  {
    path: '/diagnostics/lab-results/:pk',
    name: 'lab-result-details',
    component: () => import(/* webpackChunkName: "results-details" */ './views/diagnostics/LabResultsDetails.vue'),
  },
  {
    path: '/telemedicine',
    name: 'telemedicine',
    redirect: { name: 'calendar' },
    children: [
      {
        redirect: { name: 'appointments' },
        path: '',
      },
      {
        redirect: { name: 'visit-review' },
        path: 'visit-review',
      },
    ],
  },
  {
    path: '/telemedicine/appointments/:pk',
    redirect(to) {
      return { name: 'appointment-details', params: to.params };
    },
  },
  {
    path: '/telemedicine/new-appointment',
    redirect: { name: 'appointment-create' },
  },
  {
    path: '/patient-tracker',
    name: 'patient-tracker',
    component: () => import(/* webpackChunkName: "patient-tracker" */ './views/tracker/PatientTracker.vue'),
  },
  {
    path: '/patient-segments',
    name: 'patient-segments',
    component: () => import(/* webpackChunkName: "patient-segments" */ './views/segments/PatientSegments.vue'),
  },
  {
    // messaging inbox with a selected patient
    path: '/messaging/:pk?',
    name: 'messaging',
    component: () => import(/* webpackChunkName: "messaging" */ './views/Messaging.vue'),
  },
  {
    path: '/patients',
    name: 'patients',
    component: () => import(/* webpackChunkName: "patients" */ './views/patients/Patients.vue'),
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "patients" */ './views/patients/ClientDatabase.vue'),
  },
  {
    beforeEnter: useNavigationGuardBeforeEnterVerifyWindowsBetaFeature,
    path: '/patients/:pk',
    name: 'patient-details',
    component: () =>
      import(/* webpackChunkName: "patient-details" */ './views/patients/PatientDetails/PatientDetails.vue'),
  },
  {
    beforeEnter: useNavigationGuardBeforeEnterCheckParams('patientId'),
    path: '/patients/:patientId/results-explorer/:group?',
    name: 'patient-results-explorer',
    component: () => import(/* webpackChunkName: "patient-results-explorer" */ './views/patients/ResultsExplorer.vue'),
    props: (route: Route) => ({ ...route.params }),
  },
  {
    path: '/system-events',
    name: 'system-events',
    component: () => import(/* webpackChunkName: "system-events" */ './views/SystemEvents.vue'),
  },
  {
    path: '/state-to-provider-mapping',
    name: 'state-to-provider-mapping',
    component: () => import(/* webpackChunkName: "state-to-provider-mapping" */ './views/ProviderStates.vue'),
  },
  {
    beforeEnter: useNavigationGuardBeforeEnterCheckParams('patientId'),
    component: () =>
      import(
        /* webpackChunkName: "patient-asthma-action-plan" */ './views/patients/asthma/action-plan/PatientAsthmaActionPlanView.vue'
      ),
    name: 'patientAsthmaActionPlan',
    path: '/patients/:patientId/asthma/action-plan/:formId?',
    props: (route: Route) => ({ ...route.params }),
  },
  {
    beforeEnter: useNavigationGuardBeforeEnterCheckParams('patientId'),
    component: () =>
      import(/* webpackChunkName: "patient-scarlet-order" */ './views/patients/ScarletOrderView/ScarletOrderView.vue'),
    name: 'scarletOrder',
    path: '/patients/:patientId/scarlet/:scarletOrderId?',
    props: (route: Route) => ({ ...route.params }),
  },
  {
    component: () => import(/* webpackChunkName: "visit-template-editor" */ './views/VisitNoteTemplateEditor.vue'),
    name: 'visitTemplateEditor',
    path: '/visit-template-editor',
  },
  {
    component: () => import(/* webpackChunkName: "tools" */ './views/tools/WorkflowSendArt.vue'),
    name: 'toolsWorkflowSendArt',
    path: '/tools/workflow-send-art',
  },

  // Tasks (Provider Actions)
  {
    name: 'provider-actions',
    path: '/provider-actions',
    redirect: { name: 'tasks' },
  },
  {
    component: () => import(/* webpackChunkName: "tasks" */ './views/tasks/TasksView.vue'),
    children: [
      {
        components: {
          content: () => import(/* webpackChunkName: "tasks" */ './views/tasks/TasksOverdueView.vue'),
        },
        name: 'tasksOverdue',
        path: 'overdue',
      },
      {
        components: {
          content: () => import(/* webpackChunkName: "tasks" */ './views/tasks/TasksDueTodayView.vue'),
        },
        name: 'tasksDueToday',
        path: 'due-today',
      },
      {
        components: {
          content: () => import(/* webpackChunkName: "tasks" */ './views/tasks/TasksFuture.vue'),
        },
        name: 'tasksFuture',
        path: 'future',
      },
      {
        components: {
          content: () => import(/* webpackChunkName: "tasks" */ './views/tasks/TasksCompletedView.vue'),
        },
        name: 'tasksCompleted',
        path: 'completed',
      },
      {
        path: '',
        redirect: { name: 'tasksDueToday' },
      },
      {
        path: '*',
        redirect: { name: 'tasksDueToday' },
      },
    ],
    name: 'tasks',
    path: '/tasks',
  },
  // Notes
  {
    component: () => import(/* webpackChunkName: "notesViews" */ './views/notes/NotesView.vue'),
    children: [
      {
        components: {
          content: () => import(/* webpackChunkName: "notesViews" */ './views/notes/NotesDraftsView.vue'),
        },
        name: 'notesDrafts',
        path: 'drafts',
      },
      {
        components: {
          content: () => import(/* webpackChunkName: "notesViews" */ './views/notes/NotesReviewsView.vue'),
        },
        name: 'notesReviews',
        path: 'reviews',
      },
      {
        path: '',
        redirect: { name: 'notesReviews' },
      },
      {
        path: '*',
        redirect: { name: 'notesReviews' },
      },
    ],
    name: 'notes',
    path: '/notes',
  },

  // Calendar
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "calendar" */ './views/telemedicine/Telemedicine.vue'),
    children: [
      {
        components: {
          telemedicineContent: () =>
            import(/* webpackChunkName: "appointments" */ './views/telemedicine/Appointments.vue'),
        },
        name: 'appointments',
        path: '',
      },
      {
        components: {
          telemedicineContent: () =>
            import(/* webpackChunkName: "visit-review" */ './views/telemedicine/VisitReview.vue'),
        },
        name: 'visit-review',
        path: 'visit-review',
      },
    ],
  },
  {
    path: '/calendar/appointments/:pk',
    name: 'appointment-details',
    component: () =>
      import(/* webpackChunkName: "appointment-details" */ './views/telemedicine/AppointmentDetails.vue'),
  },
  {
    path: '/calendar/new-appointment',
    name: 'appointment-create',
    component: () => import(/* webpackChunkName: "appointment-create" */ './views/telemedicine/AppointmentForm.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes,
});
// mechanism to open last opened page when visiting Home

const LS_ROUTE_KEY = 'CRX_LAST_ROUTE';

router.afterEach((to) => {
  if (to.name === 'dashboard' || to.name === 'login') {
    return;
  }

  const route = { name: to.name, params: to.params, query: to.query };
  localStorage.setItem(LS_ROUTE_KEY, JSON.stringify(route));
});

router.beforeEach((to, from, next) => {
  const lastRoute = localStorage.getItem(LS_ROUTE_KEY);

  if (to.name !== 'dashboard' || !lastRoute) {
    next();
    return;
  }

  next(JSON.parse(lastRoute));
});

export default router;
