import { acceptHMRUpdate, defineStore } from 'pinia';
import Vue, { computed, ref } from 'vue';
import { Builder } from 'builder-pattern';
import { isValid, parseISO } from 'date-fns';

import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';

import type { AssigneeOption } from '../types/assigneeOption';

import { AssignOptionModel } from '../models/AssignOptionModel';
import { ChatModel } from '../models/ChatModel';
import { ClientModel } from '../models/ClientModel';
import { InboxStafferOrTeamOptionModel } from '../models/InboxStafferOrTeamOptionModel';
import { MessageModel } from '../models/MessageModel';
import { MessagesFilterModel } from '../models/MessagesFilterModel';
import { TeamModel } from '../models/TeamModel';

import { TeamsCacheService } from '../services/TeamsCache';
import { ChatsCacheService } from '../services/ChatsCache';

import { isSeparator } from '../validation/isSeparator';

import { useLocalStorage } from '@vueuse/core';

import type { AccountType, ReplySuggestionType } from '@/types';
import { AccountRoleEnum, ChatSegmentEnum, MessageChannelEnum } from '@/types';

import { useStaffersStore } from '@/stores/staffers';
import type { ChatFailedMessagePropsModel } from '@/components/ChatFailedMessage/models';

// Todo: remove Vue.set when migrating to Vue 3
// Everything should work just with indexes

// consider this when using:
// actions can be performed multiple times with same data, but no change will occur

const useMessagingStore = defineStore('messaging', () => {
  const staffersStore = useStaffersStore();

  const _timestamp = ref<number>(Date.now());
  const _idle = ref<boolean>(false);
  const _initialized = ref<boolean>(false);

  const _inboxActions = ref<Record<AssigneeOption, Record<ChatSegmentEnum, number>>>({});
  const _chatHasMoreMessages = ref<Record<ChatModel['key'], boolean>>({});
  const _chatsActions = ref<Record<ChatModel['id'], number>>({});

  const _searchInChats = ref<string>('');
  const _searchInChat = ref<string>('');

  const _selectionEnabled = ref<boolean>(false);

  const _currentStafferOrTeam = ref<AssigneeOption>(ChatSegmentEnum.ALL);
  const _currentFilter = ref<ChatSegmentEnum>(ChatSegmentEnum.ASSIGNED);

  const _recentlyUpdatedChats = ref<Record<AssigneeOption, ChatModel['key'][]>>({});

  const _activeChat = ref<ChatModel['key'] | ''>('');
  const _currentChannel = ref<MessageChannelEnum>(MessageChannelEnum.EMAIL);
  const _teams = ref<TeamModel['id'][]>([]);
  const _selectedMessages = ref<Record<ChatModel['key'], MessageModel['key'][]>>({});
  const _inboxes = ref<Record<AssigneeOption, Record<ChatSegmentEnum, ChatModel['key'][]>>>({});
  const _inboxOpenChats = ref<Record<TeamModel['id'], number>>({});
  const _messageDrafts = ref<Record<ChatModel['key'], Record<MessageChannelEnum, string>>>({});
  const _messageSubject = ref<Record<ChatModel['key'], string>>({});
  const _replyToMessage = ref<Record<ChatModel['key'], MessageModel['id']>>({});
  const _editMessage = ref<Record<ChatModel['key'], MessageModel['id']>>({});

  const _inboxHasMoreChats = ref<Record<AssigneeOption, Record<ChatSegmentEnum, boolean>>>({});

  // displayed in the inbox preview, even if some filters are applied
  const _inboxChatLastMessage = ref<Record<ChatModel['key'], MessageModel['key']>>({});
  const _inboxChatLastClientMessage = ref<Record<ChatModel['key'], MessageModel['key']>>({});
  const _chatMessages = ref<Record<ChatModel['key'], MessageModel['key'][]>>({});
  const _chatFailedMessages = ref<Record<ChatModel['key'], ChatFailedMessagePropsModel[]>>({});
  const _chatClient = ref<Record<ChatModel['key'], ClientModel['key']>>({});

  const _messageReplySuggestionsLoading = ref<Record<MessageModel['id'], boolean>>({});
  const _messageReplySuggestions = ref<Record<MessageModel['id'], ReplySuggestionType['id'][]>>({});

  const _messagesFilter = useLocalStorage<MessagesFilterModel>(
    'pinia/messaging/messages-filter',
    new MessagesFilterModel()
  );

  const timestamp = computed((): number => _timestamp.value);
  const idle = computed((): boolean => _idle.value);

  const isSelectionEnabled = computed((): boolean => _selectionEnabled.value);
  const isAutomationMessagesFiltered = computed((): boolean => _messagesFilter.value.automation);

  const searchInChats = computed((): string => _searchInChats.value);
  const searchInChat = computed((): string => _searchInChat.value);

  const currentStafferOrTeam = computed((): AssigneeOption => _currentStafferOrTeam.value);
  const currentFilter = computed(
    (): ChatSegmentEnum => (searchInChats.value?.length ? ChatSegmentEnum.ALL : _currentFilter.value)
  );

  const recentlyUpdatedChats = computed((): Record<AssigneeOption, ChatModel['key'][]> => _recentlyUpdatedChats.value);
  const activeChat = computed((): ChatModel['key'] => _activeChat.value);
  const currentClient = computed((): ClientModel['key'] => _chatClient.value?.[_activeChat.value]);
  const messageReplySuggestionsLoading = computed(
    (): Record<MessageModel['id'], boolean> => _messageReplySuggestionsLoading.value
  );
  const messageReplySuggestions = computed(
    (): Record<MessageModel['id'], ReplySuggestionType['id'][]> => _messageReplySuggestions.value
  );
  const inboxChatsLastMessage = computed(
    (): Record<ChatModel['key'], MessageModel['key']> => _inboxChatLastMessage.value
  );
  const inboxChatsLastClientMessage = computed(
    (): Record<ChatModel['key'], MessageModel['key']> => _inboxChatLastClientMessage.value
  );
  const currentChatHasMoreMessages = computed((): boolean => !!_chatHasMoreMessages.value?.[_activeChat.value]);
  const currentStafferInbox = computed(
    (): Record<ChatSegmentEnum, ChatModel['key'][]> =>
      _inboxes.value?.[_currentStafferOrTeam.value] || ({} as Record<ChatSegmentEnum, ChatModel['key'][]>)
  );
  const currentInbox = computed((): ChatModel['key'][] => currentStafferInbox.value?.[currentFilter.value] || []);
  const currentInboxHasMoreChats = computed((): boolean => {
    const hasMoreChats: boolean | undefined =
      _inboxHasMoreChats.value?.[_currentStafferOrTeam.value]?.[currentFilter.value];

    return isUndefined(hasMoreChats) ? true : hasMoreChats;
  });

  const currentChannel = computed((): MessageChannelEnum => _currentChannel.value);
  const currentChatLatestMessage = computed(
    (): MessageModel['key'] | void => _inboxChatLastMessage.value?.[_activeChat.value] || null
  );
  const currentChatLatestClientMessage = computed(
    (): MessageModel['key'] | void => _inboxChatLastClientMessage.value?.[_activeChat.value] || null
  );
  const currentChatHasUnansweredClientMessage = computed(
    (): boolean =>
      currentChatLatestMessage.value &&
      currentChatLatestClientMessage.value &&
      currentChatLatestMessage.value === currentChatLatestClientMessage.value
  );
  const currentMessages = computed((): MessageModel['key'][] => _chatMessages.value?.[_activeChat.value] || []);
  const currentFailedMessages = computed(
    (): ChatFailedMessagePropsModel[] => _chatFailedMessages.value?.[_activeChat.value] || []
  );
  const currentSelectedMessages = computed(
    (): MessageModel['key'][] => _selectedMessages.value?.[_activeChat.value] || []
  );
  const currentDraftMessage = computed(
    (): string => _messageDrafts.value?.[_activeChat.value]?.[_currentChannel.value] || ''
  );
  const currentMessageSubject = computed((): string => _messageSubject.value?.[activeChat.value] || '');
  const currentReplyToMessage = computed((): string => _replyToMessage.value?.[activeChat.value] || '');
  const currentEditMessage = computed((): string => _editMessage.value?.[activeChat.value] || '');

  const currentChatActions = computed((): number => _chatsActions.value?.[_activeChat.value] || 0);

  const chatsMessages = computed((): Record<ChatModel['key'], MessageModel['key'][]> => _chatMessages.value);
  const chatsFailedMessages = computed(
    (): Record<ChatModel['key'], ChatFailedMessagePropsModel[]> => _chatFailedMessages.value || {}
  );
  const hasInboxActions = computed(
    (): boolean => !!_inboxActions.value?.[_currentStafferOrTeam.value]?.[currentFilter.value]
  );

  const currentChatLastMessage = computed(
    (): MessageModel['key'] | '' =>
      currentMessages.value?.find((messageKey: MessageModel['key']) => !isValid(parseISO(messageKey))) || ''
  );

  const currentChatFirstMessage = computed(
    (): MessageModel['key'] | '' =>
      currentMessages.value?.findLast((messageKey: MessageModel['key']) => !isValid(parseISO(messageKey))) || ''
  );

  const isInitialized = computed((): boolean => _initialized.value);

  const inboxes = computed((): Record<AssigneeOption, Record<ChatSegmentEnum, ChatModel['key'][]>> => _inboxes.value);
  const inboxOpenChats = computed((): Record<TeamModel['id'], number> => _inboxOpenChats.value || {});

  const selectedMessages = computed((): MessageModel['key'][] =>
    currentMessages.value.filter((value: MessageModel['key']) => currentSelectedMessages.value.includes(value))
  );

  const _staffers = computed((): AccountType[] => {
    return [
      staffersStore.currentStaffer?.id && staffersStore.currentStaffer,
      ...staffersStore.staffers
        .filter((staffer: AccountType) => staffer?.id !== staffersStore.currentStaffer?.id)
        .filter((staffer: AccountType) => staffer?.isActive)
        .filter((staffer: AccountType) => staffer?.isAvailableAsMessagingAssignee)
        .filter((staffer: AccountType) => [AccountRoleEnum.admin, AccountRoleEnum.doctor].includes(staffer?.role)),
    ].filter(Boolean);
  });

  const inboxStafferOrTeamOptions = computed((): InboxStafferOrTeamOptionModel[] => [
    ..._staffers.value.map((staffer: AccountType) =>
      Builder(InboxStafferOrTeamOptionModel)
        .key(staffer.id)
        .label(staffer.displayName || 'Name not provided')
        .selected(_currentStafferOrTeam.value === staffer.id)
        .build()
    ),
    hasTeams.value && Builder(InboxStafferOrTeamOptionModel).key('separator-1').build(),
    ..._teams.value
      .map((teamId: TeamModel['id']): InboxStafferOrTeamOptionModel => {
        const team: TeamModel = TeamsCacheService.get(teamId);

        const openChatsNumber: number = inboxOpenChats.value?.[team.id] || 0;
        const openChats: string = openChatsNumber > 99 ? '99+' : openChatsNumber.toString();

        return (
          team &&
          Builder(InboxStafferOrTeamOptionModel)
            .key(team.id)
            .label(team.name || 'Team not provided')
            .openChats(!openChatsNumber ? '' : openChats)
            .selected(_currentStafferOrTeam.value === team.id)
            .build()
        );
      })
      .filter(Boolean),
  ]);

  const assignOptions = computed((): AssignOptionModel[] => {
    const activeChatModel: ChatModel | void = ChatsCacheService.get(_activeChat.value);

    const assignedIds: Array<AssigneeOption> = activeChatModel
      ? [...(activeChatModel?.staffersIds || []), ...(activeChatModel?.teamsIds || [])]
      : [];

    const validAssignOptions: AssignOptionModel[] = inboxStafferOrTeamOptions.value.map(
      (inboxStafferOrTeamOption: InboxStafferOrTeamOptionModel): AssignOptionModel => {
        return Builder(AssignOptionModel)
          .key(inboxStafferOrTeamOption.key)
          .label(inboxStafferOrTeamOption.label)
          .build();
      }
    );

    const missingStaffers: AssignOptionModel[] = (activeChatModel?.staffersIds || [])
      .filter(Boolean)
      .filter(
        (stafferId: AccountType['id']) =>
          !validAssignOptions.find(
            (assignOption: AssignOptionModel) => !isSeparator(assignOption.key) && assignOption.key === stafferId
          )
      )
      .map((stafferId: AccountType['id']): AssignOptionModel => {
        const missingStaffer: AccountType | void = staffersStore.staffers.find(
          (staffer: AccountType) => staffer?.id === stafferId
        );

        return missingStaffer
          ? Builder(AssignOptionModel)
              .key(missingStaffer.id)
              .label(`${missingStaffer.displayName} (not active)`)
              .selected(true)
              .build()
          : Builder(AssignOptionModel).key(stafferId).label(`${stafferId} (assignee unknown)`).selected(true).build();
      });

    return [
      ...validAssignOptions.map((assignOption: AssignOptionModel): AssignOptionModel => {
        const selected: boolean = assignedIds.includes(assignOption.key);
        const label: string =
          assignOption.key === staffersStore.currentStaffer?.id && !selected ? 'Assign to me' : assignOption.label;

        return Builder(AssignOptionModel, assignOption).label(label).selected(selected).build();
      }),
      ...missingStaffers,
    ];
  });

  const hasTeams = computed((): boolean => !!_teams.value.length);
  const hasPendingActions = computed((): boolean => !!currentChatActions.value);

  function setSelectionEnabled(newSelectionEnabled: boolean): void {
    _selectionEnabled.value = newSelectionEnabled;
  }

  function setMessagesAutomationFilter(newMessagesAutomationFilter: boolean): void {
    _messagesFilter.value.automation = newMessagesAutomationFilter;
  }

  function toggleSelectedMessage(newMessageKey: MessageModel['key'] | void): void {
    if (!newMessageKey) {
      return;
    }

    if (!currentSelectedMessages.value.includes(newMessageKey)) {
      setSelectedMessages(activeChat.value, [...currentSelectedMessages.value, newMessageKey]);
      return;
    }

    setSelectedMessages(
      activeChat.value,
      currentSelectedMessages.value.filter((messageKey: MessageModel['key']) => newMessageKey !== messageKey)
    );
  }

  function setInitialized(initialized = false): void {
    _initialized.value = initialized;
  }

  function setSelectedMessages(chatKey: ChatModel['key'], newSelectedMessages: MessageModel['key'][]): void {
    if (_selectedMessages.value?.[chatKey]?.length && isEqual(_selectedMessages.value[chatKey], newSelectedMessages)) {
      return;
    }

    Vue.set(_selectedMessages.value, chatKey, newSelectedMessages);
  }

  function setTimestamp(newTimestamp: number = Date.now()): void {
    _timestamp.value = newTimestamp;
  }

  function setIdle(newIdle: boolean = false): void {
    _idle.value = newIdle;
  }

  function setSearchInChats(searchText: string = ''): void {
    if (!_inboxHasMoreChats.value?.[_currentStafferOrTeam.value]?.[currentFilter.value]) {
      setInboxHasMoreChats(_currentStafferOrTeam.value, currentFilter.value, true);
    }

    if (_inboxes.value?.[_currentStafferOrTeam.value]?.[currentFilter.value]) {
      _inboxes.value[_currentStafferOrTeam.value][currentFilter.value] = [];
    }

    _searchInChats.value = searchText;
  }

  function setSearchInChat(searchText: string = ''): void {
    if (!_chatHasMoreMessages.value?.[_activeChat.value]) {
      setChatHasMoreMessages(_activeChat.value, true);
    }

    if (_chatMessages.value?.[_activeChat.value]?.length) {
      _chatMessages.value[_activeChat.value] = [];
    }

    _searchInChat.value = searchText;
  }

  function setCurrentStafferOrTeam(newStafferOrTeam: AssigneeOption): void {
    _currentStafferOrTeam.value = newStafferOrTeam;
  }

  function setCurrentFilter(newFilter: ChatSegmentEnum): void {
    _currentFilter.value = newFilter;
  }

  function setActiveChat(newChatKey: ChatModel['key']): void {
    if (_activeChat.value === newChatKey) {
      return;
    }

    if (!_chatHasMoreMessages.value?.[newChatKey]) {
      setChatHasMoreMessages(newChatKey, true);
    }

    _activeChat.value = newChatKey;
  }

  // todo: improve
  // chat is migrated from old key regardless if this chat was already added to the store
  function migrateChat(oldChatKey: ChatModel['key'], newChatKey: ChatModel['key']): void {
    if (_chatHasMoreMessages.value?.[oldChatKey]) {
      setChatHasMoreMessages(newChatKey, _chatHasMoreMessages.value[oldChatKey]);
    }

    if (_chatsActions.value?.[oldChatKey]) {
      setChatActions(newChatKey, _chatsActions.value[oldChatKey]);
    }

    if (_inboxChatLastMessage.value?.[oldChatKey] && !_inboxChatLastMessage.value[newChatKey]) {
      setInboxChatLastMessage(newChatKey, _inboxChatLastMessage.value[oldChatKey]);
    }

    if (_inboxChatLastClientMessage.value?.[oldChatKey] && !_inboxChatLastClientMessage.value[newChatKey]) {
      setInboxChatLastClientMessage(newChatKey, _inboxChatLastClientMessage.value[oldChatKey]);
    }

    if (_chatMessages.value?.[oldChatKey]?.length) {
      setChatMessages(newChatKey, _chatMessages.value[oldChatKey]);
    }

    if (_chatFailedMessages.value?.[oldChatKey]?.length) {
      setFailedChatMessages(newChatKey, _chatFailedMessages.value[oldChatKey]);
    }

    if (_chatClient.value?.[oldChatKey]) {
      setChatClient(newChatKey, _chatClient.value[oldChatKey]);
    }

    if (_selectedMessages.value?.[oldChatKey]?.length) {
      setSelectedMessages(newChatKey, _selectedMessages.value[oldChatKey]);
    }

    if (_messageSubject.value?.[oldChatKey]) {
      setSubject(newChatKey, _messageSubject.value[oldChatKey]);
    }

    if (_replyToMessage.value?.[oldChatKey]) {
      setReplyToMessage(newChatKey, _replyToMessage.value[oldChatKey]);
    }

    if (_editMessage.value?.[oldChatKey]) {
      setEditMessage(newChatKey, _editMessage.value[oldChatKey]);
    }

    if (_messageDrafts.value?.[oldChatKey]) {
      for (const channel in _messageDrafts.value[oldChatKey]) {
        const formattedChannel: MessageChannelEnum = channel as MessageChannelEnum;
        setDraft(newChatKey, formattedChannel, _messageDrafts.value[oldChatKey][formattedChannel] || '');
      }
    }

    updateChatKeyInInboxes(oldChatKey, newChatKey);

    delete _chatHasMoreMessages.value[oldChatKey];
    delete _chatsActions.value[oldChatKey];
    delete _inboxChatLastMessage.value[oldChatKey];
    delete _inboxChatLastClientMessage.value[oldChatKey];
    delete _chatMessages.value[oldChatKey];
    delete _chatFailedMessages.value[oldChatKey];
    delete _chatClient.value[oldChatKey];
    delete _messageDrafts.value[oldChatKey];
    delete _messageSubject.value[oldChatKey];
    delete _replyToMessage.value[oldChatKey];
    delete _editMessage.value[oldChatKey];
    delete _selectedMessages.value[oldChatKey];
  }

  function setChatClient(chatKey: ChatModel['key'], newChatClientKey: ChatModel['key']): void {
    if (_chatClient.value?.[chatKey] === newChatClientKey) {
      return;
    }

    Vue.set(_chatClient.value, chatKey, newChatClientKey);
  }

  function setChatHasMoreMessages(chatKey: ChatModel['key'], hasMoreMessages: boolean): void {
    // _chatHasMoreMessages.value[chatKey] = hasMoreMessages;
    if (_chatHasMoreMessages.value?.[chatKey] === hasMoreMessages) {
      return;
    }

    Vue.set(_chatHasMoreMessages.value, chatKey, hasMoreMessages);
  }

  function setInboxChats(
    stafferOrTeamId: AssigneeOption,
    filter: ChatSegmentEnum,
    chatsKeys: ChatModel['key'][]
  ): void {
    if (
      _inboxes.value?.[stafferOrTeamId]?.[filter]?.length &&
      isEqual(_inboxes.value[stafferOrTeamId][filter], chatsKeys)
    ) {
      return;
    }

    Vue.set(_inboxes.value, stafferOrTeamId, {
      ...(_inboxes.value?.[stafferOrTeamId] || {}),
      [filter]: chatsKeys,
    });
  }

  function setInboxOpenChats(newOpenChats: Record<TeamModel['id'], number> = {}): void {
    if (isEqual(_inboxOpenChats.value, newOpenChats)) {
      return;
    }

    _inboxOpenChats.value = newOpenChats;
  }

  // this is a shortcut, this blacklist should mimic inboxes 1:1
  function setRecentlyUpdatedChats(stafferOrTeamId: AssigneeOption, chatsKeys: ChatModel['key'][]): void {
    if (
      _recentlyUpdatedChats.value?.[stafferOrTeamId]?.length &&
      isEqual(_recentlyUpdatedChats.value[stafferOrTeamId], chatsKeys)
    ) {
      return;
    }

    Vue.set(_recentlyUpdatedChats.value, stafferOrTeamId, chatsKeys);
  }

  function removeChatFromOldAssignees(
    unwantedChatKey: ChatModel['key'],
    newAssignedTo: ChatModel['staffersIds'] & ChatModel['teamsIds']
  ): void {
    for (const id in _inboxes.value) {
      if (!id || !Object.prototype.hasOwnProperty.call(_inboxes.value, id) || !_inboxes.value?.[id]) {
        continue;
      }

      if (newAssignedTo.includes(id)) {
        continue;
      }

      for (const filter in _inboxes.value[id]) {
        if (!filter || !Object.prototype.hasOwnProperty.call(_inboxes.value[id], filter)) {
          continue;
        }

        const newChatKeys: ChatModel['key'][] = _inboxes.value[id][filter as ChatSegmentEnum].filter(
          (chatKey: ChatModel['key']) => chatKey !== unwantedChatKey
        );

        setInboxChats(id, filter as ChatSegmentEnum, newChatKeys);
      }
    }
  }

  function removeChatFromOldFilters(unwantedChatKey: ChatModel['key'], newActiveFilters: ChatSegmentEnum[]): void {
    for (const id in _inboxes.value) {
      if (!id || !Object.prototype.hasOwnProperty.call(_inboxes.value, id) || !_inboxes.value?.[id]) {
        continue;
      }

      for (const filter in _inboxes.value[id]) {
        if (!filter || !Object.prototype.hasOwnProperty.call(_inboxes.value[id], filter)) {
          continue;
        }

        if (newActiveFilters.includes(filter as ChatSegmentEnum)) {
          continue;
        }

        const newChatKeys: ChatModel['key'][] = _inboxes.value[id][filter as ChatSegmentEnum].filter(
          (chatKey: ChatModel['key']) => chatKey !== unwantedChatKey
        );

        setInboxChats(id, filter as ChatSegmentEnum, newChatKeys);
      }
    }
  }

  //Record<AssigneeOption, Record<ChatSegmentEnum, ChatModel['key'][]>>
  function removeChatFromUnsnoozedFilters(
    unwantedChatKey: ChatModel['key'],
    newSnoozedForIds: ChatModel['snoozedIds']
  ): void {
    for (const id in _inboxes.value) {
      if (!id || !Object.prototype.hasOwnProperty.call(_inboxes.value, id) || !_inboxes.value?.[id]) {
        continue;
      }

      for (const filter in _inboxes.value[id]) {
        if (!filter || !Object.prototype.hasOwnProperty.call(_inboxes.value[id], filter)) {
          continue;
        }

        if (filter === ChatSegmentEnum.SNOOZED && newSnoozedForIds.includes(id)) {
          continue;
        }

        const newChatKeys: ChatModel['key'][] = _inboxes.value[id][filter as ChatSegmentEnum].filter(
          (chatKey: ChatModel['key']) => chatKey !== unwantedChatKey
        );

        setInboxChats(id, filter as ChatSegmentEnum, newChatKeys);
      }
    }
  }

  function updateChatKeyInInboxes(oldChatKey: ChatModel['key'], newChatKey: ChatModel['key']): void {
    for (const id in _inboxes.value) {
      if (!id || !Object.prototype.hasOwnProperty.call(_inboxes.value, id) || !_inboxes.value?.[id]) {
        continue;
      }

      for (const filter in _inboxes.value[id]) {
        if (!filter || !Object.prototype.hasOwnProperty.call(_inboxes.value[id], filter)) {
          continue;
        }

        const newChatKeys: ChatModel['key'][] = _inboxes.value[id][filter as ChatSegmentEnum].map(
          (chatKey: ChatModel['key']) => (chatKey === oldChatKey ? newChatKey : chatKey)
        );

        setInboxChats(id, filter as ChatSegmentEnum, newChatKeys);
      }
    }
  }

  function setInboxHasMoreChats(
    stafferOrTeamId: AssigneeOption,
    filter: ChatSegmentEnum,
    hasMorePatients: boolean
  ): void {
    // _inboxHasMoreChats.value[filter] = hasMorePatients;
    if (_inboxHasMoreChats.value?.[stafferOrTeamId]?.[filter] === hasMorePatients) {
      return;
    }

    Vue.set(_inboxHasMoreChats.value, stafferOrTeamId, {
      ...(_inboxHasMoreChats.value?.[stafferOrTeamId] || {}),
      [filter]: hasMorePatients,
    });
  }

  function setCurrentChannel(newChannel: MessageChannelEnum): void {
    _currentChannel.value = newChannel;
  }

  function updateChatMessage(
    chatKey: ChatModel['key'],
    oldMessageKey: MessageModel['key'],
    newMessageKey: MessageModel['key']
  ): void {
    if (_inboxChatLastMessage.value?.[chatKey] === oldMessageKey) {
      setInboxChatLastMessage(chatKey, newMessageKey);
    }

    if (_inboxChatLastClientMessage.value?.[chatKey] === oldMessageKey) {
      setInboxChatLastClientMessage(chatKey, newMessageKey);
    }

    if (_selectedMessages.value?.[chatKey]?.includes(oldMessageKey)) {
      setSelectedMessages(
        chatKey,
        _selectedMessages.value[chatKey].map((key: MessageModel['key']) =>
          key === oldMessageKey ? newMessageKey : key
        )
      );
    }

    if (_chatMessages.value?.[chatKey]?.includes(oldMessageKey)) {
      setChatMessages(
        chatKey,
        _chatMessages.value[chatKey].map((key: MessageModel['key']) => (key === oldMessageKey ? newMessageKey : key))
      );
    }
  }

  function deleteChatMessage(chatKey: ChatModel['key'], messageKey: MessageModel['key']): void {
    if (!_chatMessages.value?.[chatKey]?.length || !_chatMessages.value[chatKey]?.includes(messageKey)) {
      return;
    }

    setChatMessages(
      chatKey,
      _chatMessages.value[chatKey].filter((key: MessageModel['key']) => key !== messageKey)
    );
  }

  function setChatMessages(chatKey: ChatModel['key'], messageKeys: MessageModel['key'][]): void {
    // _chatMessages.value[chatKey] = messageKeys;
    if (_chatMessages.value?.[chatKey]?.length && isEqual(_chatMessages.value[chatKey], messageKeys)) {
      return;
    }

    Vue.set(_chatMessages.value, chatKey, messageKeys);
  }

  function setFailedChatMessages(chatKey: ChatModel['key'], requests: ChatFailedMessagePropsModel[]): void {
    // _chatFailedMessages.value[chatKey] = messageKeys;
    if (_chatFailedMessages.value?.[chatKey]?.length && isEqual(_chatFailedMessages.value[chatKey], requests)) {
      return;
    }

    Vue.set(_chatFailedMessages.value, chatKey, requests);
  }

  function setInboxChatLastMessage(chatKey: ChatModel['key'], messageKey: MessageModel['key']): void {
    // _chatMessages.value[chatKey] = messageKeys;
    if (_inboxChatLastMessage.value?.[chatKey] && _inboxChatLastMessage.value?.[chatKey] === messageKey) {
      return;
    }

    Vue.set(_inboxChatLastMessage.value, chatKey, messageKey);
  }

  function setInboxChatLastClientMessage(chatKey: ChatModel['key'], messageKey: MessageModel['key']): void {
    // _chatMessages.value[chatKey] = messageKeys;
    if (_inboxChatLastClientMessage.value?.[chatKey] && _inboxChatLastClientMessage.value?.[chatKey] === messageKey) {
      return;
    }

    Vue.set(_inboxChatLastClientMessage.value, chatKey, messageKey);
  }

  function setDraft(chatKey: ChatModel['key'], channel: MessageChannelEnum, newDraft: string): void {
    if (_messageDrafts.value?.[chatKey]?.[channel] === newDraft) {
      return;
    }

    Vue.set(_messageDrafts.value, chatKey, {
      ...(_messageDrafts.value?.[chatKey] || {}),
      [channel]: newDraft,
    });
  }

  function setCurrentDraft(newDraft: string): void {
    setDraft(activeChat.value, currentChannel.value, newDraft);
  }

  function setSubject(chatKey: ChatModel['key'], newSubject: string): void {
    if (_messageSubject.value?.[chatKey] === newSubject) {
      return;
    }

    Vue.set(_messageSubject.value, chatKey, newSubject);
  }

  function setCurrentSubject(newSubject: string): void {
    setSubject(activeChat.value, newSubject);
  }

  function setReplyToMessage(chatKey: ChatModel['key'], newReplyToId: MessageModel['id']): void {
    if (_replyToMessage.value?.[chatKey] === newReplyToId) {
      return;
    }

    Vue.set(_replyToMessage.value, chatKey, newReplyToId);
  }

  function setCurrentReplyToMessage(newReplyToId: MessageModel['id']): void {
    setReplyToMessage(activeChat.value, newReplyToId);
  }

  function setEditMessage(chatKey: ChatModel['key'], newEditMessageId: MessageModel['id']): void {
    if (_editMessage.value?.[chatKey] === newEditMessageId) {
      return;
    }

    Vue.set(_editMessage.value, chatKey, newEditMessageId);
  }

  function setCurrentEditMessage(newEditMessageId: MessageModel['id']): void {
    setEditMessage(activeChat.value, newEditMessageId);
  }

  function setTeams(newIds: TeamModel['id'][]): void {
    _teams.value = newIds;
  }

  function setChatActions(chatId: ChatModel['id'], actionsCount: number = 0): void {
    // _chatsActions.value[chatId] = actionsCount;
    if (_chatsActions.value?.[chatId] === actionsCount) {
      return;
    }

    Vue.set(_chatsActions.value, chatId, actionsCount);
  }

  function setMessageReplySuggestionsLoading(messageId: MessageModel['id'], loading: boolean = false): void {
    // _messageReplySuggestionsLoading.value[messageId] = loading;
    if (_messageReplySuggestionsLoading.value?.[messageId] === loading) {
      return;
    }

    Vue.set(_messageReplySuggestionsLoading.value, messageId, loading);
  }

  function setMessageReplySuggestions(messageId: MessageModel['id'], replyIds: ReplySuggestionType['id'][]): void {
    // _messageReplySuggestions.value[messageId] = replyIds;
    if (
      _messageReplySuggestions.value?.[messageId]?.length &&
      isEqual(_messageReplySuggestions.value?.[messageId], replyIds)
    ) {
      return;
    }

    Vue.set(_messageReplySuggestions.value, messageId, replyIds);
  }

  function increaseChatActions(chatId: ChatModel['id']): void {
    setChatActions(chatId, (_chatsActions.value?.[chatId] || 0) + 1);
  }

  function decreaseChatActions(chatId: ChatModel['id']): void {
    setChatActions(chatId, (_chatsActions.value?.[chatId] || 1) - 1);
  }

  function setInboxActions(stafferOrTeamId: AssigneeOption, filter: ChatSegmentEnum, actionsCount: number = 0): void {
    if (_inboxActions.value?.[stafferOrTeamId]?.[filter] === actionsCount) {
      return;
    }

    Vue.set(_inboxActions.value, stafferOrTeamId, {
      ...(_inboxActions.value?.[stafferOrTeamId] || {}),
      [filter]: actionsCount,
    });
  }

  function increaseInboxActions(stafferOrTeamId: AssigneeOption, filter: ChatSegmentEnum): void {
    setInboxActions(stafferOrTeamId, filter, (_inboxActions.value?.[stafferOrTeamId]?.[filter] || 0) + 1);
  }

  function decreaseInboxActions(stafferOrTeamId: AssigneeOption, filter: ChatSegmentEnum): void {
    setInboxActions(stafferOrTeamId, filter, (_inboxActions.value?.[stafferOrTeamId]?.[filter] || 1) - 1);
  }

  function resetCurrentSelectedMessages(): void {
    setSelectedMessages(activeChat.value, []);
  }

  function updateSubject(newSubject: string = ''): void {
    setCurrentSubject(newSubject);
    setCurrentReplyToMessage('');
  }

  // $reset is reserved pinia name
  function $reset() {
    _initialized.value = false;
    _searchInChats.value = '';
    _searchInChat.value = '';
    _selectionEnabled.value = false;
    _currentFilter.value = ChatSegmentEnum.ASSIGNED;
    _activeChat.value = '';
    _chatHasMoreMessages.value = {};
    _chatMessages.value = {};
    _chatFailedMessages.value = {};
    _currentChannel.value = MessageChannelEnum.EMAIL;
    _teams.value = [];
    _chatsActions.value = {};
    _messageReplySuggestions.value = {};
    _inboxActions.value = {};
    _inboxHasMoreChats.value = {};
    _selectedMessages.value = {};
    _inboxes.value = {};
    _messageDrafts.value = {};
    _messageSubject.value = {};
    _replyToMessage.value = {};
    _editMessage.value = {};
    _recentlyUpdatedChats.value = {};
    _messagesFilter.value = new MessagesFilterModel();
  }

  return {
    isSelectionEnabled,
    isAutomationMessagesFiltered,

    searchInChats,
    searchInChat,

    timestamp,
    idle,

    currentStafferOrTeam,
    currentFilter,

    recentlyUpdatedChats,
    activeChat,
    currentClient,
    inboxChatsLastMessage,
    inboxChatsLastClientMessage,
    currentChatHasMoreMessages,
    currentStafferInbox,
    currentInbox,
    currentInboxHasMoreChats,
    currentChannel,
    currentChatLatestMessage,
    currentChatLatestClientMessage,
    currentChatHasUnansweredClientMessage,
    currentMessages,
    currentFailedMessages,
    currentDraftMessage,
    currentMessageSubject,
    currentReplyToMessage,
    currentEditMessage,

    currentChatActions,
    messageReplySuggestionsLoading,
    messageReplySuggestions,
    currentChatLastMessage,
    currentChatFirstMessage,
    chatsMessages,
    chatsFailedMessages,
    isInitialized,

    inboxes,

    hasInboxActions,

    selectedMessages,

    inboxStafferOrTeamOptions,
    assignOptions,

    hasTeams,
    hasPendingActions,

    setInitialized,

    setSelectionEnabled,
    setMessagesAutomationFilter,
    toggleSelectedMessage,
    setSelectedMessages,

    setTimestamp,
    setIdle,

    setSearchInChats,
    setSearchInChat,
    setCurrentStafferOrTeam,
    setCurrentFilter,

    setActiveChat,
    setChatClient,
    setChatHasMoreMessages,
    setInboxChats,
    setInboxOpenChats,
    setRecentlyUpdatedChats,
    setInboxHasMoreChats,
    setCurrentChannel,
    setChatMessages,
    setFailedChatMessages,
    setInboxChatLastMessage,
    setInboxChatLastClientMessage,
    setDraft,
    setCurrentDraft,
    setCurrentSubject,
    setCurrentReplyToMessage,
    setCurrentEditMessage,
    setTeams,

    migrateChat,

    setMessageReplySuggestionsLoading,
    setMessageReplySuggestions,
    increaseChatActions,
    decreaseChatActions,

    increaseInboxActions,
    decreaseInboxActions,

    resetCurrentSelectedMessages,

    removeChatFromOldAssignees,
    removeChatFromOldFilters,
    removeChatFromUnsnoozedFilters,

    updateSubject,
    updateChatMessage,
    deleteChatMessage,

    $reset,
  };
});

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useMessagingStore, import.meta.webpackHot));
}

export { useMessagingStore };
