import { Action } from '@/components/ChatMessageContextMenu/types/action';

import { MessageChannelEnum } from '@/types';

const COMPONENT_NAME: 'ChatMessage' = 'ChatMessage' as const;

const channelActions: Record<MessageChannelEnum, Action[]> = {
  [MessageChannelEnum.EMAIL_AND_SMS]: [], // its not a real message channel yet
  [MessageChannelEnum.NOTE]: [Action.UPDATE, Action.DELETE, Action.MARK_AS_IMPORTANT],
  [MessageChannelEnum.EMAIL]: [Action.REPLY_TO, Action.MARK_AS_IMPORTANT],
  [MessageChannelEnum.SMS]: [Action.MARK_AS_IMPORTANT],
  [MessageChannelEnum.ACTION]: [Action.MARK_AS_IMPORTANT],
  [MessageChannelEnum.VOICE]: [Action.MARK_AS_IMPORTANT],
  [MessageChannelEnum.INAPP]: [],
  [MessageChannelEnum.UNKNOWN]: [],
};

export { COMPONENT_NAME, channelActions };
