import gql from 'graphql-tag';

const ADD_PATIENT_TAG = gql`
  mutation ($patientId: UUID!, $tagId: UUID!) {
    addPatientTag(patientId: $patientId, tagId: $tagId) {
      status
    }
  }
`;

export { ADD_PATIENT_TAG };
