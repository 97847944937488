import type { PrescriptionType } from '@/types';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { PrescriptionKindEnum } from '@/types/PrescriptionKindEnum';
import {
  environmentalCommonFormulations,
  type EnvironmentalCommonFormulationsKey,
  foodCommonFormulations,
  type FoodCommonFormulationsKey,
} from '@/views/subscriptions/commonFormulations';

const defaultDilutions = {
  food: {
    anaphylactic: { rx1: 10, rx2: 10, rx3: 9, rx4: 9 },
    default: { rx1: 8, rx2: 7, rx3: 6, rx4: 5 },
  },
  environmental: {
    renewal: { rx1: 0, rx2: 0, rx3: 0, rx4: 0 },
    default: { rx1: 1, rx2: 0, rx3: 0, rx4: 0 },
  },
};

export type CreatePrescriptionSLITOptions<Type extends 'Environmental' | 'Food'> = {
  kind: PrescriptionType['kind'];
  type: Type;
  selected_common_formulation: Type extends 'Food' ? FoodCommonFormulationsKey : EnvironmentalCommonFormulationsKey;
} & (Type extends 'Food' ? { anaphylactic: boolean } : { anaphylactic?: never });

function createPrescriptionSLITBase<Type extends 'Environmental' | 'Food'>(
  options: CreatePrescriptionSLITOptions<Type>
): PrescriptionType {
  return {
    kind: options.kind,
    data: {
      type: options.type,
      anaphylactic: options.anaphylactic,
      antigens: {},
      treatment_dilution: {},
      selected_common_formulation: options.selected_common_formulation,
    },
  };
}

function applyPrescriptionSLITEnv(rx: PrescriptionType): PrescriptionType {
  const isRenewal = [PrescriptionKindEnum.RENEWAL, PrescriptionKindEnum.FORMULA_MODIFICATION].includes(rx.kind);
  const dilution = isRenewal ? defaultDilutions.environmental.renewal : defaultDilutions.environmental.default;

  const formulation = rx.data.selected_common_formulation || 'Custom';
  const antigens = environmentalCommonFormulations[formulation] ?? environmentalCommonFormulations['Custom'];

  rx.data = {
    ...rx.data,
    treatment_dilution: { ...dilution },
    antigens: { ...antigens },
  };

  return rx;
}

function applyPrescriptionSLITFood(rx: PrescriptionType): PrescriptionType {
  const dilution = rx.data.anaphylactic ? defaultDilutions.food.default : defaultDilutions.food.anaphylactic;

  const formulation = rx.data.selected_common_formulation || 'Custom';
  const antigens = foodCommonFormulations[formulation] ?? foodCommonFormulations['Custom'];

  rx.data = {
    ...rx.data,
    treatment_dilution: { ...dilution },
    antigens: { ...antigens },
  };

  return rx;
}

export function createPrescriptionSLIT<Type extends 'Environmental' | 'Food'>(
  options: MaybeRefOrGetter<CreatePrescriptionSLITOptions<Type>>
) {
  const rx = createPrescriptionSLITBase(toValue(options));

  return rx.data.type === 'Food' ? applyPrescriptionSLITFood(rx) : applyPrescriptionSLITEnv(rx);
}
