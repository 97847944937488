enum MessageChannelEnum {
  EMAIL_AND_SMS = 'email-and-sms', // frontend only
  UNKNOWN = 'unknown',
  INAPP = 'inapp',
  EMAIL = 'email',
  SMS = 'sms',
  NOTE = 'note',
  ACTION = 'action',
  VOICE = 'voice',
}

export { MessageChannelEnum };
