import gql from 'graphql-tag';
import { ExternalLabRequisitionFragment, ScarletOrderFragment } from '@/fragments';
import { patientCardDetailsFragment } from '@/components/PatientCard/gql';

import { MESSAGE } from '~/messaging';
import { MEDIAL_DOCUMENT } from '~/medicalDocuments';

const QUESTIONNAIRE = `
    questions {
      id
      text
      shortText

      displayInEhr
      displayOrder

      group {
        code
        name
        displayOrder
      }
    }

    answeredQuestions {
      question {
        id
      }
      answers {
        value
        placeholder
        isTextField
        additionalDetails
      }
      session {
        id
        version
        completedAt
        updatedAt
      }
    }
`;

const GET_PATIENT_DETAILS = gql`
  ${MESSAGE}
  ${MEDIAL_DOCUMENT}
  query GetPatientDetails($patientId: UUID!) {
    patient(id: $patientId) {
      ...patientCardDetails
      state
      timezone
      pharmacyAddressStr
      pharmacyAddress {
        name
        address
        city
        state
        zipcode
      }
      tags {
        id
      }
      questionnaire {
        ${QUESTIONNAIRE}
      }

      preRenewalSurveyQuestionnaire {
        ${QUESTIONNAIRE}
      }

      artSurveyQuestionnaire {
        ${QUESTIONNAIRE}
      }

      providerActions {
        id
        status
        displayStatus
        description
        kind
        displayKind
        createdAt
        updatedAt
        followUpDate
        assignee {
          id
          firstName
          lastName
        }
        appointment {
          id
          startTime
          endTime
          timezone
        }
      }
      laborder {
        id
        status
        displayStatus
        updatedAt
        createdAt
        orderingPhysician {
          id
          firstName
          lastName
        }
        labKit {
          id
          serialNumber
        }
      }
      labresult {
        id
        status
        displayStatus
        s3DownloadLink
        results
        createdAt
        labOrder {
          id
          labKit {
            id
            serialNumber
          }
        }
        releasingPhysician {
          id
          firstName
          lastName
        }
      }
      externalLabOrders {
        ...externalLabRequisition
      }
      defaultPollenRegions
      dxLaboratory
      idCards {
        id
        cardImageFront
        cardImageBack
      }
      externalLabResults {
        id
        s3Link
        createdAt
        label
        uploadedBy {
          firstName
          lastName
        }
      }
      medicalDocuments {
        ...MedicalDocument
      }
      asthmaActionPlans {
        id
        s3Link
        createdAt
        label
        uploadedBy {
          firstName
          lastName
        }
      }
      insuranceCards {
        id
        insuranceProvider
        policyholderName
        policyholderDateOfBirth
        policyholderMemberId
        cardImageFront
        cardImageBack
        createdAt
        updatedAt
        uploadedBy {
          firstName
          lastName
        }
      }
      insurancePayments {
        stripeId
        date
        amountDollars
        createdAt
        isRefund
        linkToStripe
      }
      insuranceClass
      notes {
        id
        timestamp
        content
        status
        displayStatus
        SLITCancellationReason
        createdAt
        owner {
          id
          firstName
          lastName
        }
        appointment {
          id
          kind
          timezone
          outcomes {
            title
          }
        }
        attachedMessages {
          ...Message
        }
      }
      appointments {
        id
        startTime
        endTime
        displayStatus
        displayKind
        kind
        status
        calendlyId
        timezone
        notes {
          id
          content
        }
        physician {
          id
        }
      }
      prescriptionSet {
        id
        status
        kind
        displayStatus
        treatmentKind
        createdAt
        updatedAt
        signedAt
        pharmacy
        physician {
          id
          firstName
          lastName
          email
          displayRole
        }
        displayTreatmentKitTypes
      }
      scarletOrders {
        ...scarletOrdersFragment
      }
      epipenPrescription {
        type
        createdAt
      }
      shipments {
        id
        pharmacy
        shippedAt
        carrier
        isEarly
        trackingNumber
        trackingUrl
        description
        weightLossPrescriptionId
        preRenewalSurveyDate
        tags {
          id
          name
        }
      }
      nextShipmentDate
      subsequentShipmentDate
      subsequentShipmentDateSetBy {
        id
      }
      paymentPeriod
      adminSiteUrl
      cancellationReason
      voiceCalls {
        id
        startedAt
        createdAt
        direction
        outcome
        duration
        staffer {
          id
          firstName
          lastName
        }
      }
      weightLossPrescriptions {
        createdAt
        displayStatus
        externalId
        id
        medication
        signedAt
        status
        updatedAt
        pharmacy
        kind
        provider {
          id
        }
      }
    }
  }
  ${patientCardDetailsFragment}
  ${ExternalLabRequisitionFragment}
  ${ScarletOrderFragment}
`;

export { GET_PATIENT_DETAILS };
