export function expandIn(target: HTMLElement, GSAP: typeof gsap) {
  if (!GSAP || !target) {
    return null;
  }

  function onComplete() {
    target.style.height = 'auto';
  }

  return GSAP.fromTo(target, { height: 0 }, { height: target.scrollHeight, ease: 'power2.out', onComplete });
}
