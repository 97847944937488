import type { StafferType } from '@/types';
import { AccountRoleEnum } from '@/types';
import { State } from '@/services/states/types';

const stafferDefault: StafferType = {
  createdAt: '',
  displayName: '',
  displayNameShort: '',
  displayRole: '',
  ehrBetaFeatures: [],
  email: '',
  firstName: '',
  id: '',
  isActive: false,
  isAvailableAsMessagingAssignee: false,
  lastName: '',
  personalCalendlyLink: '',
  personalZoomLink: '',
  phone: '',
  role: AccountRoleEnum.client,
  state: State.AK,
  updatedAt: '',
  npi: '',
  teams: [],
  groups: [],
  title: '',
};

const STAFFERS_BLACK_LIST: StafferType['id'][] = [
  '0174474c-2c84-cd91-b728-48c65271329d', // production Ravi
  '01865be1-36ec-d8d9-0671-6ab4fedb1f05', // IT Support
];

// there is no call agents team on staging...
const CALL_AGENTS_TEAM: '0189ad15-7198-aa57-e8e5-fb9b3edb99a3' = '0189ad15-7198-aa57-e8e5-fb9b3edb99a3' as const;
// Replace to make it working on staging
// const CALL_AGENTS_TEAM: '0195483c-8565-32a8-cf79-fe62481cba12' = '0195483c-8565-32a8-cf79-fe62481cba12' as const;

const TWELVE_HOURS: 4.32e7 = 4.32e7 as const;

const TOKEN_KEY: 'token' = 'token' as const;
const LAST_UPDATED_KEY: 'lastUpdated' = 'lastUpdated' as const;
const BETA_FEATURES_KEY: 'currentUserBetaFeatures' = 'currentUserBetaFeatures' as const;

export {
  stafferDefault,
  STAFFERS_BLACK_LIST,
  CALL_AGENTS_TEAM,
  TWELVE_HOURS,
  TOKEN_KEY,
  LAST_UPDATED_KEY,
  BETA_FEATURES_KEY,
};
