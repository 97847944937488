import { MessageChannelEnum } from '@/types';

class ChatFailedMessagePropsModel {
  public id: string = '';

  public channel: MessageChannelEnum = MessageChannelEnum.UNKNOWN;
  public chatId: string = '';
  public message: string = '';

  public attachmentIds: string[] = [];
  public replyTo: string = '';
  public subject: string = '';
  public errorMessage: string = 'Message not sent.';
}

export { ChatFailedMessagePropsModel };
