export function diffObjects(standard: Record<any, number>, obj: Record<any, number>): Record<any, number> {
  const mergedObjects = Object.fromEntries(Object.keys({ ...standard, ...obj }).map((key) => [key, 0]));

  return Object.keys(mergedObjects).reduce(
    (diff, key) => {
      const value = ((obj[key] || 0) * 10 - (standard[key] || 0) * 10) / 10;

      return value ? { ...diff, [key]: value } : diff;
    },
    { ...mergedObjects }
  );
}
