import gql from 'graphql-tag';

const REMOVE_PATIENT_TAG = gql`
  mutation ($patientId: UUID!, $tagId: UUID!) {
    removePatientTag(patientId: $patientId, tagId: $tagId) {
      status
    }
  }
`;

export { REMOVE_PATIENT_TAG };
