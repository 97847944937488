export function fadeInDown(target: HTMLElement, GSAP: typeof gsap) {
  if (!GSAP || !target) {
    return null;
  }

  return GSAP.fromTo(
    target,
    { opacity: 0, transform: 'translate3d(0, -100%, 0)' },
    { opacity: 1, transform: 'translate3d(0, 0, 0)' }
  );
}
