
import { computed, defineComponent, getCurrentInstance, ref, toRef, toRefs } from 'vue';
import { Builder } from 'builder-pattern';

import {
  CHAT_ACTION_LIMIT,
  ChatModel,
  ChatsCacheService,
  SendMessageRequest,
  useMessaging,
  useMessagingApi,
  useMessagingStore,
} from '~/messaging';

import { ChatFailedMessagePropsModel } from './models';

import { buildPropsFromModel } from '@/tools/props';
import { showError } from '@/components/errors';
import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';

import { MessageChannelEnum } from '@/types';
import { getRandomId } from '@/tools';

const ChatFailedMessage = defineComponent({
  props: buildPropsFromModel(new ChatFailedMessagePropsModel()),
  setup(props: ChatFailedMessagePropsModel) {
    const vm = getCurrentInstance?.()?.proxy;

    const loading = ref<boolean>(false);

    const {
      id,
      channel,
      chatId,
      message,

      attachmentIds,
      replyTo,
      subject,
      errorMessage,
    } = toRefs<ChatFailedMessagePropsModel>(props);

    const { sendMessage } = useMessagingApi();

    const messagingStore = useMessagingStore();
    const chatsFailedMessages = toRef(messagingStore, 'chatsFailedMessages');

    const { setFailedChatMessages, increaseChatActions, decreaseChatActions } = messagingStore;

    const { addOrUpdateChat } = useMessaging();
    const channelIconProps = computed(() => {
      switch (channel.value) {
        case MessageChannelEnum.EMAIL:
          return { name: 'envelope' };
        case MessageChannelEnum.INAPP:
          return { name: 'comment' };
        case MessageChannelEnum.NOTE:
          return { name: 'notes-medical' };
        case MessageChannelEnum.ACTION:
          return { name: 'clipboard' };
        case MessageChannelEnum.SMS:
          return { name: 'message' };
        case MessageChannelEnum.VOICE:
          return { name: 'voicemail' };
      }

      return { name: 'question' };
    });

    const channelText = computed((): string => {
      switch (channel.value) {
        case MessageChannelEnum.EMAIL:
          return 'Email';
        case MessageChannelEnum.INAPP:
          return 'In-app';
        case MessageChannelEnum.ACTION:
          return 'Action';
        case MessageChannelEnum.NOTE:
          return 'Note';
        case MessageChannelEnum.SMS:
          return 'SMS';
        case MessageChannelEnum.VOICE:
          return 'Voicecall';
      }

      return 'Unknown';
    });

    const discardError = (copyChatId: string = chatId.value, copyId: string = id.value): void => {
      const currentChatModelUpdated: ChatModel | void = ChatsCacheService.getById(copyChatId);
      if (!currentChatModelUpdated || loading.value) {
        return;
      }

      setFailedChatMessages(
        currentChatModelUpdated.key,
        chatsFailedMessages.value?.[currentChatModelUpdated.key]?.length
          ? chatsFailedMessages.value[currentChatModelUpdated.key].filter(
              (errors: ChatFailedMessagePropsModel) => errors.id !== copyId
            )
          : []
      );
    };

    const retryRequest = async (): Promise<void> => {
      const currentChatModel: ChatModel | void = ChatsCacheService.getById(chatId.value);
      if (!currentChatModel || loading.value) {
        return;
      }

      const currentErrorId: string = id.value;

      const request: SendMessageRequest = Builder<SendMessageRequest>()
        .chatId(chatId.value)
        .limit(CHAT_ACTION_LIMIT)
        .onlyMessagesAfter(currentChatModel.updatedAt)
        .channels([channel.value])
        .message(message.value)
        .replyTo(replyTo.value || undefined)
        .subject(subject.value || undefined)
        .attachmentIds(attachmentIds.value.length ? attachmentIds.value : undefined)
        .build();

      loading.value = true;
      increaseChatActions(request.chatId);

      try {
        addOrUpdateChat(await sendMessage(request));
      } catch (error: unknown) {
        const errorMessage: string = parseGraphQLErrorsAsText(error);
        showError(vm, `Failed to retry sending a message. ${errorMessage}`, 50000);

        const currentChatModelWithError: ChatModel | void = ChatsCacheService.getById(request.chatId);
        if (!currentChatModelWithError) {
          return;
        }

        const chatFailedMessageProps: ChatFailedMessagePropsModel = Builder<ChatFailedMessagePropsModel>()
          .id(getRandomId())
          .channel(request?.channels?.[0] || MessageChannelEnum.UNKNOWN)
          .chatId(request?.chatId)
          .message(request?.message)
          .attachmentIds(request?.attachmentIds)
          .replyTo(request?.replyTo)
          .subject(request?.subject)
          .errorMessage(errorMessage.replace('Reason: ', '').replace('Reasons: ', ''))
          .build();

        setFailedChatMessages(
          currentChatModelWithError.key,
          chatsFailedMessages.value?.[currentChatModelWithError.key]?.length
            ? [...chatsFailedMessages.value[currentChatModelWithError.key], chatFailedMessageProps]
            : [chatFailedMessageProps]
        );
      } finally {
        decreaseChatActions(request.chatId);
        loading.value = false;

        discardError(request.chatId, currentErrorId);
      }
    };

    return {
      loading,
      message,

      channelIconProps,
      channelText,
      errorMessage,

      retryRequest,
      discardError,
    };
  },
});

export default ChatFailedMessage;
