import { GsapService } from '@/services/gsap';
import type { UseGSAPAnimationFunction } from '@/composables/useGSAPAnimation/types/useGSAPAnimationFunction';

function useGSAPAnimationImplementation() {
  const queue = new Set<[gsap.TweenTarget, (UseGSAPAnimationFunction | gsap.core.Tween)[], Set<[string, any[]]>]>();

  let gsap: typeof GsapService.get.gsap;

  GsapService.load().then(() => {
    gsap = GsapService.get.gsap;
    executeQueue();
  });

  function chainCall(chain: Set<[string, any[]]>) {
    const proxy = new Proxy(
      {},
      {
        get(_: any, propName: string) {
          return (...args: any[]) => {
            chain.add([propName, args]);

            return proxy;
          };
        },
      }
    );

    return proxy;
  }

  function executeQueue() {
    queue.forEach(([target, animations, chain]) => {
      const timeline = animate(target, ...animations);

      chain.forEach(([fnName, args]) => timeline[fnName](...args));
    });

    queue.clear();
  }

  function targetToHTMLElement(target: gsap.TweenTarget): HTMLElement | null {
    return typeof target === 'string' ? document.querySelector(target) : (target as HTMLElement);
  }

  function animate(
    target: gsap.TweenTarget,
    ...animations: (UseGSAPAnimationFunction | gsap.core.Tween)[]
  ): gsap.core.Timeline {
    if (!gsap) {
      const chain = new Set<[string, any[]]>();
      queue.add([target, animations, chain]);

      return chainCall(chain);
    }

    const timeline = gsap.timeline();
    const el = targetToHTMLElement(target);

    animations.forEach((animation) =>
      typeof animation === 'function' ? timeline.add(animation(el, gsap), '<') : timeline.add(animation, '<')
    );

    return timeline;
  }

  return {
    animate,
    get isReady(): boolean {
      return !!gsap;
    },
  };
}

let useGSAPAnimationInstance: ReturnType<typeof useGSAPAnimationImplementation>;

export function useGSAPAnimation() {
  return useGSAPAnimationInstance || (useGSAPAnimationInstance = useGSAPAnimationImplementation());
}
