import { MessageCategoryEnum, MessageChannelEnum, MessageType } from '@/types';

const isSystemMessage = (message: MessageType): boolean => {
  if (message?.channel === MessageChannelEnum.ACTION) {
    return false;
  }

  return ![
    MessageCategoryEnum.MESSAGE,
    MessageCategoryEnum.MARKETING_MESSAGE,
    MessageCategoryEnum.FILE,
    MessageCategoryEnum.NOTE,
    MessageCategoryEnum.LOGIN_LINK,
    MessageCategoryEnum.PASSWORD_RESET_LINK,
    MessageCategoryEnum.EMAIL_CONFIRMATION_LINK,
    MessageCategoryEnum.ASYNC_NOTIFICATION,
    MessageCategoryEnum.PRECLINICAL_NOTIFICATION,
    MessageCategoryEnum.RENEWAL_NOTIFICATION,
    MessageCategoryEnum.REFILL_NOTIFICATION,
    MessageCategoryEnum.INSURANCE_CONSULTATION_NOTIFICATION,
    MessageCategoryEnum.OFFICE_HOURS_NOTIFICATION,
    MessageCategoryEnum.CALL_TRANSCRIPT_SUMMARY,
    MessageCategoryEnum.CALL_TRANSCRIPT,
  ].includes(message?.category);
};

export { isSystemMessage };
