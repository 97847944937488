import type { PrescriptionType } from '@/types';
import {
  createPrescriptionSLIT,
  type CreatePrescriptionSLITOptions,
} from '@/composables/getPrescriptionDiff/utils/createPrescriptionSLIT';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { diffWithOther } from '@/composables/getPrescriptionDiff/utils/diffWithOther';

export function diffWithDefault(prescription: MaybeRefOrGetter<PrescriptionType<Record<string, any>>>) {
  const rx = toValue(prescription);

  const options: CreatePrescriptionSLITOptions<any> = {
    anaphylactic: rx?.data?.anaphylactic,
    kind: rx.kind,
    selected_common_formulation: rx.data.selected_common_formulation,
    type: rx?.data?.type,
  };

  const defaultRx = createPrescriptionSLIT(options);

  return diffWithOther(rx, defaultRx);
}
