enum MessageStatusEnum {
  CREATED = 'created',
  SENT = 'sent',
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
  RECEIVED = 'received',
  SENDING_FAILURE = 'sending_failure',
  FAILED = 'failed',
  READ = 'read',
  QUEUED = 'queued',
  ACCEPTED = 'accepted',
  SCHEDULED = 'scheduled',
  CANCELED = 'canceled',
  SENDING = 'sending',
  RECEIVING = 'receiving',
}

export { MessageStatusEnum };
