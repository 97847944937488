import memoize from 'lodash/memoize';

import { createList } from './createList';
import { getStafferFullNameAndEmail } from './getStafferFullNameAndEmail';

import type { StafferType } from '@/types';
import uniqBy from 'lodash/uniqBy';

// default bootstrap select type & dropdown types:
interface Option {
  text: string; // select type
  value: string; // select type, in bootstrap, selected value, is a *value* from option
  disabled?: boolean; // select type & dropdown type
  active?: boolean; // dropdown type
}

// todo: add unassign

// every argument is passed like this, to keep those properties reactive
const createSelectList = (
  staffers: StafferType[] = [],
  currentStaffer?: StafferType | void,
  selectedStaffers: StafferType['id'][] = [],
  addUnassigned: boolean = false
): Option[] => {
  const formattedList: Option[] = createList(staffers, currentStaffer)
    .map((account: StafferType) => {
      const isActive: boolean = selectedStaffers.includes(account.id);
      const isDisabled: boolean = !account?.isActive;
      const isMe: boolean = currentStaffer && currentStaffer?.id === account.id;

      const text: string = isActive
        ? getStafferFullNameAndEmail(account)
        : isMe
        ? `Assign to me (${account?.email})`
        : getStafferFullNameAndEmail(account);

      return {
        text: isDisabled ? `${getStafferFullNameAndEmail(account)} (deactivated)` : text,
        value: account.id,
        disabled: isDisabled,
        active: isActive,
      };
    })
    .filter((option: Option) => (option.disabled ? option.active : true));

  const missingStaffers: Option[] = selectedStaffers
    .filter((stafferId: StafferType['id'] | '') => !staffers.find((staffer: StafferType) => staffer.id === stafferId))
    .map((stafferId: StafferType['id']) =>
      stafferId
        ? {
            text: 'Missing staffer ' + stafferId,
            value: stafferId,
            disabled: true,
            active: true,
          }
        : {
            text: 'Unassigned',
            value: stafferId,
            disabled: !addUnassigned,
            active: true,
          }
    );

  if (addUnassigned) {
    missingStaffers.push({
      text: 'Unassigned',
      value: '',
      disabled: false,
      active: true,
    });
  }

  return uniqBy([...formattedList, ...missingStaffers], ({ value }) => value);
};

const createSelectListMemoized: typeof createSelectList = memoize(createSelectList);

export { createSelectListMemoized as createSelectList };
