import get from 'lodash/get';
import parseAsString from 'lodash/toString';

interface GraphQLError {
  code?: number;
  message?: string;
}

function parseGraphQLErrors(errors: GraphQLError[]): string {
  if (!errors.length) {
    return '';
  }

  const errorMessage = errors
    .map((error: GraphQLError) => parseAsString(error?.message).trim())
    .filter(Boolean)
    .join(', ');

  return errors.length > 1 ? `Reasons: ${errorMessage}.` : `Reason: ${errorMessage}.`;
}

export function parseGraphQLErrorsAsText(errorToParse: unknown): string {
  return (
    parseGraphQLErrors(get(errorToParse, 'graphQLErrors', [])) ||
    parseGraphQLErrors(get(errorToParse, 'networkError.result.errors', [])) ||
    get(errorToParse, 'networkError.message', '') ||
    get(errorToParse, 'message', '')
  );
}
