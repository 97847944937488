
import { computed, defineComponent, ref, toRef, watch } from 'vue';
import { Builder } from 'builder-pattern';

import type { BFormInput } from 'bootstrap-vue/src/components/form-input';

import { addHtml, getHtml, setHtml } from '@/components/Editor/helpers';

import { useRoot } from '@/composables/atoms/useRoot';
import { ClipboardService } from '@/services/ClipboardService';

import type { MessageTemplateRequest } from '~/messaging';
import {
  ChatModel,
  ChatsCacheService,
  ClientModel,
  ClientsCacheService,
  composerEmailToolbarDefault,
  formatSmsText,
  formatStringForSearch,
  isReplyIncludes,
  isReplyStartsWith,
  RepliesCacheService,
  ReplyModel,
  useMessagingApi,
  useMessagingStore,
} from '~/messaging';

import { showError } from '@/components/errors';

import type { MessageTemplateType } from '@/types';
import { MessageChannelEnum } from '@/types';

import { useModalsStore } from '@/stores/modals/store';
import { useStaffersStore } from '@/stores/staffers';

import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';
import { usePatientsStore } from '@/stores/patients';
import parseAsString from 'lodash/toString';
import { useMessagingDrafts } from '~/messaging/composables/useMessagingDrafts';

const modalKey: 'chat-macros-modal' = 'chat-macros-modal' as const;

const defaultMacros: MessageTemplateRequest = Builder<MessageTemplateRequest>()
  .id('')
  .body('')
  .name('')
  .description('')
  .text('')
  .subject('')
  .build();

// Bootstrap tabs are starting to lag, if they are controlled by our state
const ChatMacrosModal = defineComponent({
  setup() {
    const root = useRoot();

    const staffersStore = useStaffersStore();
    const currentStaffer = toRef(staffersStore, 'currentStaffer');

    const modalsStore = useModalsStore();
    const openModals = toRef(modalsStore, 'openModals');

    const patientsStore = usePatientsStore();
    const patients = toRef(patientsStore, 'patients');

    const messagingStore = useMessagingStore();
    const messagingDrafts = useMessagingDrafts();
    const isInitialized = toRef(messagingStore, 'isInitialized');
    const currentChannel = toRef(messagingStore, 'currentChannel');
    const currentDraftMessage = toRef(messagingStore, 'currentDraftMessage');
    const currentMessageSubject = toRef(messagingStore, 'currentMessageSubject');
    const activeChat = toRef(messagingStore, 'activeChat');
    const currentClient = toRef(messagingStore, 'currentClient');

    const { setCurrentDraft, updateSubject } = messagingStore;

    const { closeModal } = modalsStore;

    const { createMessageTemplate, updateMessageTemplate } = useMessagingApi();

    const searchElement = ref<InstanceType<typeof BFormInput>>(null);

    const search = ref('');
    const loading = ref<number>(0);
    const editMode = ref<boolean>(false);
    const newMacros = ref<MessageTemplateRequest>(defaultMacros);

    const isModalOpen = computed((): boolean => openModals.value.includes(modalKey));

    const replies = computed((): ReplyModel[] =>
      isInitialized.value ? RepliesCacheService.getRepliesWithFilter(search.value) : []
    );

    const client = computed((): ClientModel => ClientsCacheService.get(currentClient.value) || new ClientModel());
    const currentChat = computed((): ChatModel => ChatsCacheService.get(activeChat.value) || new ChatModel());

    const clientName = computed(
      (): string => currentChat.value.displayNameShort || client.value.firstName || 'Patient'
    );

    const clientAllergyTriggers = computed(
      (): string =>
        patients.value?.[currentChat.value?.patientId]?.allergyTriggers
          ?.filter((trigger: string) => !!trigger.length && trigger !== 'Not sure')
          ?.join(', ')
          ?.toLowerCase() || 'Allergies'
    );

    const clientAllergyTriggersWithoutFoodAndOther = computed(
      (): string =>
        patients.value?.[currentChat.value?.patientId]?.allergyTriggers
          ?.filter((trigger: string) => !!trigger.length && !['Not sure', 'Food', 'Other'].includes(trigger))
          ?.join(', ')
          ?.toLowerCase() || 'Allergies'
    );

    const stafferName = computed(
      (): string => currentStaffer.value.displayNameShort || currentStaffer.value.firstName || 'Curex'
    );

    const stafferCredentials = computed(
      (): string =>
        currentStaffer.value.displayName ||
        `${currentStaffer.value.firstName} ${currentStaffer.value.lastName}`.trim() ||
        'Curex'
    );

    const copyClientFirstName = async (): Promise<void> => {
      ClipboardService.write('{{clientFirstName}}');
    };

    const copyStafferFirstName = async (): Promise<void> => {
      ClipboardService.write('{{stafferFirstName}}');
    };

    const copyStafferCredentials = async (): Promise<void> => {
      ClipboardService.write('{{stafferCredentials}}');
    };

    const copyClientAllergyTriggersCredentials = async (): Promise<void> => {
      ClipboardService.write('{{clientAllergyTriggers}}');
    };

    const copyClientAllergyTriggersWithoutFoodAndOtherCredentials = async (): Promise<void> => {
      ClipboardService.write('{{clientAllergyTriggersWithoutFoodAndOther}}');
    };

    const saveMacros = async (): Promise<void> => {
      loading.value++;
      try {
        if (newMacros.value?.id?.length) {
          const newMessageTemplate: MessageTemplateType = await updateMessageTemplate(newMacros.value);
          RepliesCacheService.update(newMessageTemplate);
        } else {
          const newMessageTemplate: MessageTemplateType = await createMessageTemplate(newMacros.value);
          RepliesCacheService.add(newMessageTemplate);
        }

        editMode.value = false;
        newMacros.value = defaultMacros;
      } catch (error) {
        showError(root, `Failed to save macros ${newMacros.value?.id}. ${parseGraphQLErrorsAsText(error)}`);
        console.error('saveMacros', error);
      } finally {
        loading.value--;
      }
    };

    const createMacros = async (): Promise<void> => {
      if (loading.value) {
        showError(root, `Cannot create new macros, because previous is still saving ${newMacros.value?.id}`);
        return;
      }

      newMacros.value = defaultMacros;
      editMode.value = true;
    };

    const editMacros = (id: string): void => {
      const replyModel: ReplyModel = RepliesCacheService.getById(id);
      if (!RepliesCacheService.isValidReplyModel(replyModel)) {
        showError(root, `Failed to edit macros ${id}.`);
        return;
      }

      newMacros.value = Builder<MessageTemplateRequest>()
        .id(replyModel.id)
        .body(replyModel.body)
        .name(replyModel.name)
        .description(replyModel.keyword)
        .text(replyModel.text)
        .subject(replyModel.subject)
        .build();

      editMode.value = true;
    };

    const formatText = (value: string): string => {
      return parseAsString(value)
        .replace(/{{clientFirstName}}/gi, clientName.value)
        .replace(/{{clientAllergyTriggers}}/gi, clientAllergyTriggers.value)
        .replace(/{{clientAllergyTriggersWithoutFoodAndOther}}/gi, clientAllergyTriggersWithoutFoodAndOther.value)
        .replace(/{{stafferFirstName}}/gi, stafferName.value)
        .replace(/{{stafferCredentials}}/gi, stafferCredentials.value);
    };

    const insertMacros = async (id: string): Promise<void> => {
      const reply: ReplyModel = RepliesCacheService.getById(id);
      if (id !== reply.id) {
        showError(root, 'Failed to insert macros');
        return;
      }

      const rawMessage: string = formatStringForSearch(currentDraftMessage.value);

      const formattedSubject: string = !currentMessageSubject.value.length ? formatText(reply.subject) : '';

      switch (currentChannel.value) {
        case MessageChannelEnum.ACTION:
        case MessageChannelEnum.NOTE:
        case MessageChannelEnum.EMAIL: {
          if (formattedSubject.length) {
            updateSubject(formattedSubject);
          }

          const formattedText: string = formatText(reply.body);

          if (!currentDraftMessage.value.length) {
            setCurrentDraft(formattedText);
            await setHtml(currentChat.value.id, formattedText);
            break;
          }

          if (isReplyStartsWith(reply, rawMessage) || isReplyIncludes(reply, rawMessage)) {
            setCurrentDraft(formattedText);
            await setHtml(currentChat.value.id, formattedText);
            break;
          }

          setCurrentDraft((await getHtml(currentChat.value.id)) + formattedText);
          await addHtml(currentChat.value.id, formattedText);
          break;
        }
        default: {
          const formattedText: string = formatText(reply.text);

          if (currentChannel.value === MessageChannelEnum.EMAIL_AND_SMS && formattedSubject.length) {
            updateSubject(formattedSubject);
          }

          if (!currentDraftMessage.value.length) {
            setCurrentDraft(formattedText);
            break;
          }

          if (isReplyStartsWith(reply, rawMessage) || isReplyIncludes(reply, rawMessage)) {
            setCurrentDraft(formattedText);
            break;
          }

          setCurrentDraft(currentDraftMessage.value + formattedText);
        }
      }

      root.$emit('cx-chat-macros-modal::focus');
      messagingDrafts.set(currentChat.value.key, currentChannel.value, currentDraftMessage.value);
      closeModal(modalKey);
    };

    watch(
      isModalOpen,
      (newIsModalOpen: boolean = false, oldIsModalOpen: boolean = false) => {
        if (!newIsModalOpen || newIsModalOpen === oldIsModalOpen) {
          return;
        }

        searchElement.value?.focus?.();
      },
      { immediate: true }
    );

    return {
      modalKey,

      composerEmailToolbarDefault,

      searchElement,
      loading,

      editMode,
      newMacros,
      search,
      replies,

      formatSmsText,
      copyClientFirstName,
      copyStafferFirstName,
      copyStafferCredentials,
      copyClientAllergyTriggersCredentials,
      copyClientAllergyTriggersWithoutFoodAndOtherCredentials,

      saveMacros,
      createMacros,
      editMacros,

      insertMacros,
    };
  },
});

export default ChatMacrosModal;
