export function diffCounts(obj: Record<any, any>, revert = false) {
  if (!obj) {
    return {
      total: 0,
      increase: 0,
      decrease: 0,
    };
  }

  const modifier = revert ? -1 : 1;
  return {
    total: Object.values(obj).filter(Boolean).length,
    increase: Object.values(obj)
      .filter(Boolean)
      .filter((value) => modifier * value < 0).length,
    decrease: Object.values(obj)
      .filter(Boolean)
      .filter((value) => modifier * value > 0).length,
  };
}
