// This service exists to lazy load this awesome library that doesn't support tree shaking

class Gsap {
  private loadingPromise: Promise<any> = null;
  private _gsap: typeof import('gsap') | null = null;

  public get isLoaded(): boolean {
    return !!this._gsap;
  }

  public get get(): Gsap['_gsap'] {
    return this.isLoaded ? this._gsap : null;
  }

  public async load(): Promise<void> {
    try {
      if (!this.loadingPromise) {
        /** Prevention from calling multiple import */
        this.loadingPromise = import(/* webpackMode: "lazy" */ 'gsap');
        this._gsap = await this.loadingPromise;
      }
    } catch (e) {
      console.error('Gsap error:', e);
      return (this.loadingPromise = Promise.reject(e));
    }

    return this.loadingPromise;
  }
}

const GsapService: Gsap = new Gsap();

export { GsapService };
