
import { onBeforeMount, onBeforeUnmount, watch } from 'vue';
import isObject from 'lodash/isObject';

import EditorJS from '@editorjs/editorjs';

import { EditorInstancesService } from './services/EditorInstances';
import { EditorPropsModel } from './models/EditorPropsModel';
import { getTools } from './modules/tools';

import { buildPropsFromModel } from '@/tools/props';
import { getRandomId } from '@/tools';
import { getHtml } from '@/components/Editor/helpers';

export default {
  name: 'Editor',
  emits: {
    input: (content: string) => typeof content === 'string',
    ready: (editorInstance: EditorJS) => !!editorInstance,
  },
  props: buildPropsFromModel(new EditorPropsModel()),
  setup(props: EditorPropsModel, { emit }) {
    let editorInstance: EditorJS = null;

    const editorId = `${props.id || getRandomId()}`;

    const init = async (): Promise<void> => {
      destroy();

      if (EditorInstancesService.has(editorId)) {
        console.error('Unexpected editor instance', editorId, EditorInstancesService);
        editorInstance = EditorInstancesService.get(editorId);
        return;
      }

      editorInstance = new EditorJS({
        holder: editorId,
        autofocus: props.autofocus,
        placeholder: props.placeholder,
        hideToolbar: props.hideToolbar,
        minHeight: props.minHeight,
        readOnly: props.readOnly,
        data: isObject(props.initialData) ? props.initialData : undefined,
        tools: await getTools(),
        onReady: fixConstantControlButtonsPlacement,
        onChange: emitOnChange,
      });

      if (EditorInstancesService.has(editorId)) {
        console.error('Unexpected editor instance', editorId, EditorInstancesService);
        return;
      }

      EditorInstancesService.set(editorId, editorInstance);
    };

    watch(
      () => props.autofocus,
      (newAutofocus: EditorPropsModel['autofocus'] = false): void => {
        if (!newAutofocus) {
          return;
        }

        editorInstance?.focus();
      }
    );

    watch(
      () => props.readOnly,
      async (newReadOnly: EditorPropsModel['readOnly'] = false): Promise<void> => {
        await editorInstance?.isReady;
        await editorInstance?.readOnly?.toggle?.(newReadOnly);
      }
    );

    watch(
      () => props.placeholder,
      async (
        newPlaceholder: EditorPropsModel['placeholder'] = '',
        oldPlaceholder: EditorPropsModel['placeholder'] = ''
      ): Promise<void> => {
        if (!newPlaceholder || newPlaceholder === oldPlaceholder) {
          return;
        }

        await editorInstance.isReady;
        const entryBlock = editorInstance.blocks.getBlockByIndex(0);
        if (!entryBlock) {
          // if there is no default block, after the initiation it will have placeholder from config
          return;
        }

        entryBlock.call('setPlaceholder', { placeholder: newPlaceholder });

        if (editorInstance?.configuration) {
          editorInstance.configuration.placeholder = newPlaceholder;
        }
      },
      { flush: 'post' }
    );

    const destroy = (): void => {
      if (!editorInstance) {
        return;
      }

      EditorInstancesService.delete(editorId);
      editorInstance?.destroy?.();
      editorInstance = null;
    };

    const fixConstantControlButtonsPlacement = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      editorInstance?.ui?.nodes?.wrapper?.classList?.add?.('codex-editor--narrow');
      emit('ready', editorInstance);
    };

    async function emitOnChange(): Promise<void> {
      try {
        if (!editorInstance) {
          console.error('Missing editor instance', editorId);
          return;
        }

        await editorInstance.isReady;

        if (props.readOnly) {
          return;
        }

        const content = await getHtml(editorId);

        emit('input', content);
      } catch (error) {
        console.error('Unexpected error on emit change event', error);
      }
    }

    onBeforeMount(init);
    onBeforeUnmount(destroy);

    return { editorId, editorInstance };
  },
};
