
import {
  defineComponent,
  getCurrentInstance,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue';

import { promiseTimeout, useDebounceFn, useIntersectionObserver, useTimeoutPoll } from '@vueuse/core';

import { isAfter } from 'date-fns';

import { showError } from '@/components/errors';

import {
  ChatModel,
  ChatsCacheService,
  getMessageType,
  MessageModel,
  MessagesCacheService,
  POLL_NEW_MESSAGES,
  useMessaging,
  useMessagingStore,
} from '~/messaging';

const ChatStream = defineComponent({
  setup() {
    const vm = getCurrentInstance?.()?.proxy;

    const { fetchOldMessages, fetchNewMessages } = useMessaging();

    const messagingStore = useMessagingStore();
    const activeChat = toRef(messagingStore, 'activeChat');
    const currentMessages = toRef(messagingStore, 'currentMessages');
    const currentFailedMessages = toRef(messagingStore, 'currentFailedMessages');
    const hasPendingActions = toRef(messagingStore, 'hasPendingActions');
    const currentChatLastMessage = toRef(messagingStore, 'currentChatLastMessage');
    const currentChatHasMoreMessages = toRef(messagingStore, 'currentChatHasMoreMessages');
    const idle = toRef(messagingStore, 'idle');

    const scrollToTopHelperElement = ref<HTMLDivElement>(null);
    const loadOldMessagesHelperElement = ref<HTMLDivElement>(null);

    const scrollHelperVisible = ref<boolean>(false);
    const scrollPositionBottom = ref<boolean>(false);

    const { increaseChatActions, decreaseChatActions } = messagingStore;

    const pollNewMessages = async (delay: boolean = true): Promise<void> => {
      try {
        delay && (await promiseTimeout(idle.value ? 5000 : 1000));
        const { moreAvailable, filterUsed } = await fetchNewMessages();
        if (moreAvailable && filterUsed) {
          await pollNewMessages();
        }
      } catch (error) {
        showError(vm, `Failed to poll messages of ${ChatsCacheService.get(activeChat.value)?.displayName}.`);
      }
    };

    const { pause: pausePollNewMessages, resume: resumePollNewMessages } = useTimeoutPoll(
      pollNewMessages,
      POLL_NEW_MESSAGES,
      {
        immediate: false,
      }
    );

    const {
      stop: stopLoadOldMessagesHelperObserver,
      pause: pauseLoadOldMessagesHelperObserver,
      resume: resumeLoadOldMessagesHelperObserver,
    } = useIntersectionObserver(loadOldMessagesHelperElement, async ([{ isIntersecting }]) => {
      scrollHelperVisible.value = isIntersecting;
      if (!scrollHelperVisible.value) {
        return;
      }
      await debouncedFetchOldMessages();
    });

    const {
      stop: stopScrollPositionBottomObserver,
      pause: pauseScrollPositionBottomObserver,
      resume: resumeScrollPositionBottomObserver,
    } = useIntersectionObserver(scrollToTopHelperElement, ([{ isIntersecting }]) => {
      scrollPositionBottom.value = isIntersecting;
    });

    const debouncedFetchOldMessages = useDebounceFn(async (): Promise<void> => {
      const chatModel: ChatModel | void = ChatsCacheService.get(activeChat.value);
      if (!chatModel) {
        return;
      }
      increaseChatActions(chatModel.id);

      try {
        await fetchOldMessages();
      } catch (error) {
        showError(vm, `Failed to load older messages of ${chatModel?.displayName}.`);
      } finally {
        decreaseChatActions(chatModel.id);
      }

      if (scrollHelperVisible.value && currentChatHasMoreMessages.value) {
        await debouncedFetchOldMessages();
      }
    }, 250);

    const scrollToBottom = (behavior: 'instant' | 'smooth' = 'instant'): void => {
      try {
        if (!scrollPositionBottom.value && scrollToTopHelperElement.value) {
          // @ts-expect-error ts error, it works
          scrollToTopHelperElement.value.scrollIntoView({ behavior, block: 'start', inline: 'start' });
        }
      } catch (error) {
        console.error('scrollToBottom', error);
      }
    };

    const debouncedScrollToBottom = useDebounceFn(scrollToBottom, 250); // debounce should be longer than transition duration

    const pause = (): void => {
      pausePollNewMessages();
      pauseLoadOldMessagesHelperObserver();
      pauseScrollPositionBottomObserver();
    };

    const resume = (): void => {
      resumePollNewMessages();
      resumeLoadOldMessagesHelperObserver();
      resumeScrollPositionBottomObserver();
    };

    onBeforeMount((): void => {
      debouncedScrollToBottom();
      resume();
    });

    onMounted((): void => {
      scrollToBottom();
      debouncedScrollToBottom();
    });

    onActivated((): void => {
      scrollToBottom();
      debouncedScrollToBottom();
      resume();
    });

    onBeforeUnmount((): void => {
      pause();
      stopLoadOldMessagesHelperObserver();
      stopScrollPositionBottomObserver();
    });

    onDeactivated((): void => {
      pause();
    });

    watch(
      currentChatLastMessage,
      (newMessageKey: MessageModel['key'] = '', oldMessageKey: MessageModel['key'] = '') => {
        if (!newMessageKey || newMessageKey === oldMessageKey) {
          return;
        }

        if (!oldMessageKey) {
          scrollToBottom('smooth');
          debouncedScrollToBottom();
          return;
        }

        const newMessageModel: MessageModel | void = MessagesCacheService.get(newMessageKey);
        if (!newMessageModel) {
          return;
        }

        const oldMessageModel: MessageModel | void = MessagesCacheService.get(oldMessageKey);
        if (!oldMessageModel) {
          scrollToBottom('smooth');
          debouncedScrollToBottom();
          return;
        }

        if (oldMessageModel.id === newMessageModel.id && scrollPositionBottom.value) {
          scrollToBottom();
          debouncedScrollToBottom();
          return;
        }

        const newMessageDate: Date = new Date(newMessageModel.createdAt);
        const oldMessageDate: Date = new Date(oldMessageModel.createdAt);

        // last message in the thread have not changed
        if (newMessageDate === oldMessageDate) {
          return;
        }

        // loaded old messages
        if (!isAfter(newMessageDate, oldMessageDate)) {
          return;
        }

        scrollToBottom('smooth');
        debouncedScrollToBottom();
      },
      { flush: 'post', immediate: true }
    );

    return {
      loadOldMessagesHelperElement,
      scrollToTopHelperElement,

      currentChatHasMoreMessages,
      currentMessages,
      currentFailedMessages,
      hasPendingActions,

      getMessageType,

      debouncedFetchOldMessages,
    };
  },
});

export default ChatStream;
