import type EditorJS from '@editorjs/editorjs';

import { EditorInstancesService } from '../services/EditorInstances';

const setHtml = async (id: string, value: string = ''): Promise<void> => {
  const editorInstance: EditorJS = EditorInstancesService.get(id);
  if (!editorInstance) {
    return;
  }

  await editorInstance.isReady;
  // fix
  // Uncaught TypeError: Cannot read properties of undefined (reading 'holder')
  // https://github.com/codex-team/editor.js/issues/2090
  if (!value?.length) {
    editorInstance.clear();
    return;
  }

  const fragment = document.createRange().createContextualFragment(value);

  await editorInstance.blocks.render({
    blocks: Array.from(fragment.childNodes).map((element: any) => ({
      type: 'paragraph',
      data: {
        text: element.outerHTML,
      },
    })),
  });

  // await editorInstance.blocks.renderFromHTML(value);
};

export { setHtml };
